import React from "react";
import { useAppSelector, useAppDispatch } from "../../store";
import { toggleMenu } from "../../store/application/actions";
import { Button } from "primereact/button";

const ToggleMenu = () => {
  const { menuOpen } = useAppSelector((app) => app.application);
  const dispatch = useAppDispatch();
  const toggleOpen = () => dispatch(toggleMenu());

  return (
    <Button
      icon={`pi ${menuOpen ? "pi-times" : "pi-bars"}`}
      size="small"
      severity="secondary"
      onClick={toggleOpen}
    />
  );
};

export default ToggleMenu;
