import { API_dataUpdate } from "../../../services/dataApi";
import { useAppSelector, useAppDispatch } from "../../../store";
import { updateCoverLetter } from "../../../store/data/actions";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";

const IncDateInHeaderToggle = () => {
  const { coverLetter } = useAppSelector((app) => app.data);
  const dispatch = useAppDispatch();

  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const submitData = () => {
    submit(
      API_dataUpdate,
      "data",
      "coverLetter",
      "Cover letter date in header"
    );
  };

  const handleChange = (e: InputSwitchChangeEvent) => {
    const checked = e.value || false;
    dispatch(updateCoverLetter({ dateInHeader: checked }));
    submitData();
  };

  return (
    <div className="flex align-items-center">
      <h5 className="my-0 mr-2">
        {coverLetter.dateInHeader ? "Include" : "Don't include"}
      </h5>
      <InputSwitch checked={coverLetter.dateInHeader} onChange={handleChange} />
    </div>
  );
};

export default IncDateInHeaderToggle;
