import { ResumeItem } from "../../../../store/data/types";
import templateEducation from "./templateEducation";
import templateHeader from "./templateHeader";
import templateSidebar from "./templateSidebar";
import templateWorkExperience from "./templateWorkExperience";
import templateAdditionalInfo from "./templateAdditionalInfo";
import { Page, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import React from "react";

Font.register({
  family: "Roboto",
  fonts: [
    {
      src: require("../../../../assets/roboto/Roboto-Light.ttf"),
      fontWeight: "light",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-LightItalic.ttf"),
      fontWeight: "light",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Regular.ttf"),
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-Italic.ttf"),
      fontWeight: "normal",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Bold.ttf"),
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-BoldItalic.ttf"),
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    margin: 0,
    fontWeight: "normal",
    lineHeight: 1.3,
    color: "#444",
    hyphens: "auto",
    fontSize: "8pt",
    fontFamily: "Roboto",
  },
  sectionMain: {
    marginTop: "0.1in",
    marginLeft: 10,
    marginRight: 15,
    width: 420,
    fontSize: "8pt",
    paddingBottom: 10,
  },
  sectionSidebar: {
    position: "relative" /* for disclaimer */,
    height: "100%",
    backgroundColor: "#fff",
    borderRightStyle: "solid",
    borderRightColor: "#f2f2f2",
    borderRightWidth: 0,
    width: 100,
  },
  bottomPart: {
    display: "flex",
    flexDirection: "row",
  },
  headerPart: {},
});

const Template_1 = (item: ResumeItem) => (
  <Document>
    <Page size="A4" style={{ ...styles.page, ...styles.sectionMain }} wrap>
      <View style={styles.headerPart}>{templateHeader(item, "full")}</View>

      <View style={styles.bottomPart}>
        <View
          style={styles.sectionSidebar}
          fixed
          render={({ pageNumber }) => <View key={pageNumber}>{pageNumber === 1 && templateSidebar(item)}</View>}
        />
        <View style={styles.sectionMain}>
          <View
            fixed
            render={({ pageNumber }) =>
              pageNumber === 1 ? null : (
                <View
                  style={{
                    height: 20,
                  }}
                />
              )
            }
          ></View>
          {templateWorkExperience(item)}
          {templateEducation(item)}
          {templateAdditionalInfo(item)}

          <View
            fixed
            render={() => (
              <View
                style={{
                  height: 20,
                }}
              />
            )}
          ></View>
        </View>
      </View>
    </Page>
  </Document>
);

export default Template_1;
