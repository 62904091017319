import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateUserBaseInfo } from "../../../store/data/actions";
import { Button } from "primereact/button";

const AddSolcialMediaButton = () => {
  const dispatch = useAppDispatch();

  const { socialMediaUrl } = useAppSelector((app) => app.data.baseUserInfo);

  const addNewMediaItem = () => {
    const mediaId = socialMediaUrl.length
      ? [...socialMediaUrl].sort((a, b) => (a.id > b.id ? -1 : 1))[0].id + 1
      : 1;

    dispatch(
      updateUserBaseInfo({
        socialMediaUrl: [
          ...socialMediaUrl,
          { id: mediaId, type: "linkedin", url: "" },
        ],
      })
    );
  };

  return (
    <Button
      size="small"
      severity="secondary"
      text
      raised
      onClick={addNewMediaItem}
    >
      &#43; Add social media / web
    </Button>
  );
};

export default AddSolcialMediaButton;
