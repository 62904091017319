import { ResumeItem } from "../../../store/data/types";
import template_1 from "./Skeleton/template_1";
import template_2 from "./Skeleton/template_2";
import template_3 from "./Skeleton/template_3";
import template_4 from "./Skeleton/template_4";
import template_5 from "./Skeleton/template_5";
import template_6 from "./Skeleton/template_6";

const templateGenerator = (data: ResumeItem) => {
  //based on template, different script
  switch (data.coverLetter.template.id) {
    case 1:
      return template_1(data);
    case 2:
      return template_2(data);
    case 3:
      return template_3(data);
    case 4:
      return template_4(data);
    case 5:
      return template_5(data);
    case 6:
      return template_6(data);
    default:
      return template_1(data);
  }
};

export default templateGenerator;
