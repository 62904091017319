export const SET_USER = "SET_USER";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_BILLING = "UPDATE_USER_BILLING";

export interface User {
  details: UserDetails;
  billing: UserBilling;
}

export type AccountType = "standard" | "premium";

export interface UserDetails {
  userKey: string;
  userName: string;
  email: string;
  accountType: AccountType;
}

export interface UserBilling {
  companyName: string;
  companyAddress: string;
  companyAddress2nd: string;
  companyCity: string;
  companyZip: string;
  companyState: string;
  companyCountryCode: string;
  taxId: string;
}
