import { API_dataUpdate } from "../../../services/dataApi";
import { useAppSelector, useAppDispatch } from "../../../store";
import { updateCoverLetter } from "../../../store/data/actions";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { AppTextarea } from "../Common";

const Recipient = () => {
  const { coverLetter } = useAppSelector((app) => app.data);
  const dispatch = useAppDispatch();

  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const submitData = () => {
    submit(API_dataUpdate, "data", "coverLetter", "Cover letter recipient");
  };

  const handleChange = (updatedTxt: string) => {
    dispatch(updateCoverLetter({ recipient: updatedTxt }));
    submitData();
  };

  return (
    <div className="flex sm:flex-column md:flex-row">
      <div className="w-full">
        <AppTextarea onUpdate={handleChange} value={coverLetter.recipient} />
      </div>
    </div>
  );
};

export default Recipient;
