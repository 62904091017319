import { WorkHistoryItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import HtmlBlock from "./htmlBlock";
import { renderTimePeriod } from "./templateHelper";

const styles = StyleSheet.create({
  workExperienceItem: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    fontSize: "12pt",
  },
  date: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 7,
    lineHeight: 1,
    width: 35,
    paddingTop: 5,
  },
  jobPlace: {},
  datePresent: {},
  jobTitle: {
    fontSize: "9pt",
  },
  employer: {
    fontSize: "7pt",
  },
  location: {},
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "center",
    borderLeftWidth: 1,
    borderColor: "#e4e4e4",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 5,
    position: "relative",
  },
  jobDescription: {
    padding: 0,
    margin: 0,
  },
  dot: {
    position: "absolute",
    width: 5,
    height: 5,
    backgroundColor: "#e4e4e4",
    top: 8,
    left: -3,
    borderRadius: "50%",
  },
});
// TODO add timeline next to the work items
const templateWorkExperienceItem = ({
  startDateMonth,
  startDateYear,
  stillWorkHere,
  endDateMonth,
  endDateYear,
  jobTitle,
  employer,
  city,
  state,
  jobDescription,
}: WorkHistoryItem) => (
  <View
    key={`edutaion_${jobTitle}${employer}`}
    style={styles.workExperienceItem}
  >
    <View style={styles.date}>
      <Text>{renderTimePeriod(startDateMonth, startDateYear)}</Text>
      {stillWorkHere && <Text style={styles.datePresent}>present</Text>}
      {!stillWorkHere && (
        <Text>{renderTimePeriod(endDateMonth, endDateYear)}</Text>
      )}
    </View>
    <View style={styles.content}>
      <View style={styles.dot} />
      <View style={styles.jobPlace}>
        {jobTitle.length > 0 && <Text style={styles.jobTitle}>{jobTitle}</Text>}
        {(city.length > 0 || state.length > 0 || employer.length > 0) && (
          <Text style={styles.location}>
            {city.length > 0 && city}
            {city.length > 0 && state.length > 0 && " "}
            {state.length > 0 && state}
            {(city.length > 0 || state.length > 0) &&
              employer.length > 0 &&
              ", "}
            {employer.length > 0 && employer}
          </Text>
        )}
      </View>
      {jobDescription.length > 0 && (
        <View>
          <HtmlBlock htmlCode={jobDescription} maxWidth={370} />
        </View>
      )}
    </View>
  </View>
);
export default templateWorkExperienceItem;
