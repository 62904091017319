import { ResumeItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

/*

  important issue: 
  https://github.com/diegomura/react-pdf/issues/2195

  thats because of render
*/

const styles = StyleSheet.create({
  sidebar: {
    marginLeft: 5,
  },
  sideBlockTop: {
    marginTop: 5,
  },
  sideBlock: {
    marginTop: 5,
  },
  header: {
    marginBottom: 5,
    fontSize: 12,
    color: "#444",
  },
  contactInfoBlock: {
    marginBottom: 10,
  },
  listItem: {
    marginLeft: 2,
    fontSize: 8,
    marginBottom: 2,
    color: "#444",
    fontWeight: "normal",
  },
});

const templateSidebar = ({ baseUserInfo, skills }: ResumeItem) => {
  return (
    <View style={styles.sidebar}>
      <View style={styles.sideBlockTop}>
        <Text style={styles.header}>Contact Info</Text>
        <View style={styles.contactInfoBlock}>
          {(baseUserInfo.city.length > 0 || baseUserInfo.zip.length > 0) && (
            <Text style={styles.listItem}>
              {baseUserInfo.zip.length > 0 && baseUserInfo.zip}
              {baseUserInfo.city.length > 0 && baseUserInfo.city}
            </Text>
          )}
          {baseUserInfo.state.length > 0 && (
            <Text style={styles.listItem}>{baseUserInfo.state}</Text>
          )}
          {baseUserInfo.phone.toString().length > 0 && (
            <Text style={styles.listItem}>{baseUserInfo.phone}</Text>
          )}
          {baseUserInfo.email.length > 0 && (
            <Text style={styles.listItem}>{baseUserInfo.email}</Text>
          )}
          {baseUserInfo.socialMediaUrl.map((social) => (
            <Text key={`social_${social.id}`} style={styles.listItem}>
              {social.url}
            </Text>
          ))}
        </View>

        {skills.length > 0 && (
          <View style={styles.sideBlock}>
            <Text style={styles.header}>Skills</Text>
            {[...skills]
              .filter((skill) => skill.skillName.length)
              .sort((a, b) => a.order - b.order)
              .map((skill) => (
                <Text key={`skill_${skill.id}`} style={styles.listItem}>
                  {skill.skillName}
                </Text>
              ))}
          </View>
        )}
      </View>
    </View>
  );
};

export default templateSidebar;
