import { PDFViewer } from "@react-pdf/renderer";
import { useAppSelector } from "../../../store";
import templateGenerator from "./templateGenerator";

const DownloadResume = () => {
  const { data } = useAppSelector((app) => app);
  const templateHtml = templateGenerator(data);

  return <PDFViewer style={{ width: "100%", height: "700px" }}>{templateHtml}</PDFViewer>;
};

export default DownloadResume;
