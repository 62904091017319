import {AnyAction} from 'redux';
import {FREE_PDF_DOWNLOADS_WEEKLY} from '../../constants';
import {
  PaymentState,
  SET_PAYED,
  UPDATE_FREE_ATTEMPTS,
  UPDATE_LAST_DOWNLOAD_DATE,
  UPDATE_SUBSCRIPTION_DETAILS,
} from './types';

const initialState: PaymentState = {
  payed: false,
  freeAttempts: FREE_PDF_DOWNLOADS_WEEKLY,
  lastDownloadDate: new Date(),
};

const paymentReducer = (
  state = initialState,
  {type, payload}: AnyAction,
): PaymentState => {
  switch (type) {
    case SET_PAYED:
      return {...state, payed: payload};
    case UPDATE_FREE_ATTEMPTS:
      return {...state, freeAttempts: payload};
    case UPDATE_LAST_DOWNLOAD_DATE:
      return {...state, lastDownloadDate: new Date()};
    case UPDATE_SUBSCRIPTION_DETAILS:
      return {...state, subscriptionDetails: payload};
    default:
      return state;
  }
};

export default paymentReducer;
