import React, { useState } from "react";
import ProfileAboutMePreview from "./ProfileAboutMePreview";
import ProfileAboutMeEdit from "./ProfileAboutMeEdit";

const ProfileAboutMe = () => {
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  return edit ? (
    <ProfileAboutMeEdit toggleEdit={toggleEdit} />
  ) : (
    <ProfileAboutMePreview toggleEdit={toggleEdit} />
  );
};

export default ProfileAboutMe;
