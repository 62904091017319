import React from "react";
import { EducationItem } from "../../../store/data/types";
import { useNavigate } from "react-router-dom";
import { getEducationItemTitle } from "./utlis";
import { Button } from "primereact/button";

interface IProps {
  educationItem: EducationItem;
}

const EducationSummaryItem = ({ educationItem }: IProps) => {
  const navigate = useNavigate();

  const openItemDetail = () => navigate(`${educationItem.id}`);

  return (
    <div className="w-full mb-2">
      <Button
        severity="secondary"
        size="small"
        text
        raised
        className="w-full mb-2"
        onClick={openItemDetail}
      >
        {getEducationItemTitle(educationItem)}
      </Button>
    </div>
  );
};

export default EducationSummaryItem;
