import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useAppSelector } from "../../../store";
import React from "react";
import { billingFields } from "./ProfileBilling";

interface IProps {
  toggleEdit: () => void;
}

const ProfileBillingPreview = ({ toggleEdit }: IProps) => {
  const { user } = useAppSelector((app) => app);

  const header = (
    <div className="flex p-2 align-items-center">
      <h3 className="m-0">Billing information</h3>
      <Button
        label="Edit"
        icon="pi pi-user-edit"
        severity="secondary"
        size="small"
        className="ml-auto mr-2"
        onClick={toggleEdit}
      />
    </div>
  );

  return (
    <div className="flex">
      <Card header={header} className="w-full">
        {billingFields.map((field) => (
          <div className="mb-3" key={field.valueField}>
            <p className="m-0">
              <small>{field.name}</small>
            </p>
            <p className="my-1">
              {user.billing[field.valueField].length
                ? user.billing[field.valueField]
                : "-"}
            </p>
          </div>
        ))}
      </Card>
    </div>
  );
};

export default ProfileBillingPreview;
