import React, { useEffect, useRef } from "react";
import { Messages } from "primereact/messages";

interface IProps {
  message: string;
}
const ErrorMessage = ({ message }: IProps) => {
  const msgs = useRef<Messages>(null);

  useEffect(() => {
    if (!msgs.current) return;
    if (!message.length) return;

    msgs.current?.show([
      {
        severity: "error",
        detail: message,
        closable: true,
      },
    ]);
  }, [message]);

  return (
    <div className="card">
      <Messages ref={msgs} />
    </div>
  );
};

export default ErrorMessage;
