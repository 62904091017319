const MONTHS = [
  {id: 1, label: 'Jan'},
  {id: 2, label: 'Feb'},
  {id: 3, label: 'Mar'},
  {id: 4, label: 'Apr'},
  {id: 5, label: 'May'},
  {id: 6, label: 'Jun'},
  {id: 7, label: 'Jul'},
  {id: 8, label: 'Aug'},
  {id: 9, label: 'Sep'},
  {id: 10, label: 'Oct'},
  {id: 11, label: 'Nov'},
  {id: 12, label: 'Dec'},
];

const yearGenerator = () => {
  let max = new Date().getFullYear();
  if (max < 2022) {
    max = 2022;
  }
  const min = max - 40;
  let years: number[] = [];
  for (let i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
};
const YEARS = yearGenerator();

const EDUCATION_DEGREES = [
  {id: 1, label: 'High school diploma'},
  {id: 2, label: 'General Education Development (GED)'},
  {id: 3, label: 'Associate of Arts (AA)'},
  {id: 4, label: 'Associate of Science (AS)'},
  {id: 5, label: 'Associate of Applied Science (AAS)'},
  {id: 6, label: 'Bachelor of Arts'},
  {id: 7, label: 'Bachelor of Science'},
  {id: 8, label: 'Bachelor of Business Administration (BBA)'},
  {id: 9, label: 'Master of Arts'},
  {id: 10, label: 'Master of Science'},
  {id: 11, label: 'Master of Business Administration (MBA)'},
  {id: 12, label: 'Juris Doctor (J.D.)'},
  {id: 13, label: 'Doctor of Medicine (M.D.)'},
  {id: 14, label: 'Doctor of Philosophy (Ph.D.)'},
  {id: 15, label: 'Different degree'},
  {id: 16, label: 'No degree'},
];

const FREE_PDF_DOWNLOADS_WEEKLY = 3;

const RESUME_UPLOAD_URL_SEND_EMAIL =
  'https://workstmt.com/resume/resume-over-email.php'; // 'http://localhost:8888/test.php';
const POLICY_URL = 'https://workstmt.com/resume/policy.php';
const TERMS_URL = 'https://workstmt.com/resume/terms.php';

export {
  MONTHS,
  YEARS,
  EDUCATION_DEGREES,
  RESUME_UPLOAD_URL_SEND_EMAIL,
  FREE_PDF_DOWNLOADS_WEEKLY,
  POLICY_URL,
  TERMS_URL,
};
