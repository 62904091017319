import { ResumeItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import HtmlBlock from "./htmlBlock";

const styles = StyleSheet.create({
  header: {
    textTransform: "uppercase",
  },
  profession: {},
  qualifications: {},
  marginBottom: {
    height: 4,
  },
});



const templateHeader = (
  { baseUserInfo }: ResumeItem,
  size?: "normal" | "full"
) => (
  <View>
    <Text style={styles.header}>
      {baseUserInfo.firstName} {baseUserInfo.lastName}
    </Text>
    <Text style={styles.profession}>{baseUserInfo.profession}</Text>
    {baseUserInfo.summaryOfQualifications.length > 0 && (
      <HtmlBlock
        htmlCode={baseUserInfo.summaryOfQualifications}
        maxWidth={size === "normal" ? 410 : 570}
      />
    )}
    <View style={styles.marginBottom} />
  </View>
);

export default templateHeader;
