import { Button } from "primereact/button";
import { Message } from "primereact/message";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { API_dataBaseUserInfo } from "../../../services/dataApi";
import { updateUserDetails } from "../../../store/account/actions";

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const isOk = searchParams.get("result") === "success";

  useEffect(() => {
    API_dataBaseUserInfo().then((r) => {
      dispatch(updateUserDetails(r.data.details));
    });
  }, [dispatch]);

  const baseInfo = isOk ? "Thank you for making the payment" : "Unfortunately the payment was unsuccessful";
  return (
    <Message
      className="border-primary w-full justify-content-start"
      severity={isOk ? "success" : "error"}
      text={
        <div>
          <div>{baseInfo}</div>
          <div>
            <Link to="/">
              <Button label="Go back" link />
            </Link>
          </div>
        </div>
      }
    />
  );
};

export default PaymentResult;
