import { Text } from "@react-pdf/renderer";
import React from "react";
import { EDUCATION_DEGREES } from "../../../../constants";

const renderTimePeriod = (startDate: string, endDate: string) => {
  const allDatesAreOk = () => {
    return (
      startDate !== "0" && startDate.length && endDate !== "0" && endDate.length
    );
  };
  const allDatesAreNotOk = () => {
    return (
      (startDate === "0" || !startDate.length) &&
      (endDate === "0" || !endDate.length)
    );
  };

  if (allDatesAreNotOk()) {
    return "";
  }

  let output = "";
  if (startDate !== "0" && startDate.length) {
    output += monthLandingZero(startDate);
  }
  if (allDatesAreOk()) {
    output += "-";
  }
  if (endDate !== "0" && endDate.length) {
    output += endDate;
  }

  return <Text>{output}</Text>;
};

const educationById = (educationId: number) => {
  const res = EDUCATION_DEGREES.find((e) => e.id === educationId);
  return res ? res.label : "";
};

const monthLandingZero = (date: string) => `0${date}`.slice(-2);

export { monthLandingZero, renderTimePeriod, educationById };
