import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store";
import { getEducationItemTitle } from "../Pages/Education/utlis";
import { getWorkHistoryItemTitle } from "../Pages/WorkHistory/utlis";
import ToggleMenu from "./ToggleMenu";
import { Menu } from "primereact/menu";

interface Item {
  label: string;
  icon: string;
  path: string;
  children?: boolean;
  additionalClass?: string;
  command?: () => void;
}

const LOCATIONS: Item[] = [
  { label: "User info", icon: "pi pi-user-edit", path: "/user-info" },
  {
    label: "Work history",
    icon: "pi pi-building",
    path: "/work-history",
    children: true,
  },
  {
    label: "Education",
    icon: "pi pi-book",
    path: "/education",
    children: true,
  },
  { label: "Skills", icon: "pi pi-chart-bar", path: "/skills" },
  {
    label: "Additional information",
    icon: "pi pi-pencil",
    path: "/additional-information",
  },
  {
    label: "Template select",
    icon: "pi pi-palette",
    path: "/template-select",
    additionalClass: "mt-2 pt-2 border-t-2 border-gray-100	",
  },
  {
    label: "Download resume",
    icon: "pi pi-download",
    path: "/download-resume",
  },
];

const COVER_LETTER_LOCATIONS: Item[] = [
  {
    label: "Cover letter",
    icon: "pi pi-file",
    path: "/cover-letter",
    additionalClass: "mt-2 pt-2 border-t-2 border-gray-100	",
  },
  {
    label: "Template",
    icon: "pi pi-file",
    path: "/cover-letter-template",
    additionalClass: "mt-2 pt-2 border-t-2 border-gray-100	",
  },
  {
    label: "Download",
    icon: "pi pi-file",
    path: "/cover-letter-download",
    additionalClass: "mt-2 pt-2 border-t-2 border-gray-100	",
  },
];

const truncate = (str: string, n = 20) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};

const MenuComponent = () => {
  const {
    application: { menuOpen },
    user: { details },
  } = useAppSelector((app) => app);
  const location = useLocation();
  const navigate = useNavigate();

  const { workHistory, education } = useAppSelector((app) => app.data);

  const getItemChildren = (item: Item) => {
    if (!location.pathname.includes(item.path)) return [];
    switch (item.path) {
      case "/work-history":
        return workHistory.map((workItem) => ({
          label: truncate(getWorkHistoryItemTitle(workItem)),
          command: () => navigate(`/work-history/${workItem.id}`),
          className: `pl-2 text-sm ${
            location.pathname.includes(workItem.id.toString())
              ? "font-semibold"
              : ""
          }`,
          icon: `pi pi-circle text-sm `,
        }));
      case "/education":
        return education.map((educationItem) => ({
          label: truncate(getEducationItemTitle(educationItem)),
          command: () => navigate(`/education/${educationItem.id}`),
          className: "pl-2 text-sm",
          icon: "pi pi-circle text-sm",
        }));
      default:
        return [];
    }
  };

  const menuItems = LOCATIONS.map((loc) => {
    const parentItem = {
      icon: loc.icon,
      label: loc.label,
      command: () => {
        navigate(`${loc.path}`);
      },
    };

    const childItems = getItemChildren(loc);

    return [parentItem, ...childItems];
  }).reduce((prev, next) => [...prev, ...next], []);

  const coverLetterItems = COVER_LETTER_LOCATIONS.map((loc) => ({
    icon: loc.icon,
    label: loc.label,
    command: () => {
      navigate(`${loc.path}`);
    },
  }));

  const baseMenu = [
    {
      label: "Menu",
      expanded: true,
      items: menuItems,
    },
    {
      label: "Cover letter",
      items: coverLetterItems,
      className: "mt-2",
    },
    {
      label: "User",
      className: "mt-5",
      items: [
        {
          label: "Account",
          icon: "pi pi-user",
          command: () => {
            navigate("/account");
          },
        },
        {
          label: "Logout",
          icon: "pi pi-sign-out",
          command: () => {
            navigate("/signout");
          },
          visible: details.userKey.length > 0,
        },
      ],
    },
  ];

  return (
    <div
      className={`${
        menuOpen ? "fixed z-5 px-4" : "hidden md:flex"
      } card flex-column justify-content-center h-auto p-menu`}
    >
      <div className="col  md:hidden ">
        <ToggleMenu />
      </div>
      <Menu model={baseMenu} />
    </div>
  );
};
export default MenuComponent;
