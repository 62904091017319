import React from "react";
import { InputText } from "primereact/inputtext";

interface IProps {
  value: string;
  id: string;
  label?: string;
  customInputType?: "email-address";
  isRequired?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  className?: string;
  onUpdate: (updatedVal: string) => void;
}

const AppInput = ({
  isRequired,
  id,
  customInputType,
  label,
  value,
  placeholder,
  isDisabled,
  className,
  onUpdate,
}: IProps) => {
  const onChange = (e: React.FormEvent<HTMLInputElement>) =>
    onUpdate(e.currentTarget.value);

  return (
    <div className={`flex flex-column p-float-label ${className}`}>
      <InputText
        id={id}
        type={customInputType || "text"}
        required={isRequired || false}
        disabled={isDisabled || false}
        value={value}
        onChange={onChange}
        placeholder={placeholder || label || ""}
      />
      {label && (
        <label className="text-xs" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
export default AppInput;
