import { EducationItem } from "../../../store/data/types";

export const getEducationItemTitle = (educationItem: EducationItem) => {
  let itemTitle = educationItem.schoolName;
  if (
    educationItem.schoolName.length > 0 &&
    educationItem.schoolLocation.length > 0
  )
    itemTitle += ", ";
  itemTitle += educationItem.schoolLocation;

  // if no length then default
  if (itemTitle.length < 2) {
    itemTitle = `Education item ${educationItem.id}`;
  }
  return itemTitle;
};
