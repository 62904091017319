import React from "react";
import { ResumeItem } from "../../../../store/data/types";
import templateWorkExperienceItem from "./templateWorkExperienceItem";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  workExperience: {
    marginTop: 1,
  },
  header: {
    fontSize: 12,
  },
  marginBottom: {
    height: 15,
  },
});

const templateWorkExperience = ({ workHistory }: ResumeItem) => {
  const workHistoryNonEmpty = [...workHistory]
    .filter((wh) => wh.jobTitle.length)
    .sort((a, b) => a.order - b.order);

  if (!workHistoryNonEmpty.length) {
    return null;
  }

  return (
    <View style={styles.workExperience}>
      <Text style={styles.header}>Experience</Text>
      {workHistoryNonEmpty.map(templateWorkExperienceItem)}
      {workHistoryNonEmpty.length > 0 && <View style={styles.marginBottom} />}
    </View>
  );
};

export default templateWorkExperience;
