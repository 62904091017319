import React, { useState } from "react";
import { useAppDispatch } from "../../../store";
import { removeSkillItem, updateSkillItemElement } from "../../../store/data/actions";
import { Skill } from "../../../store/data/types";
import { AppInput } from "../Common";
import { API_dataUpdate } from "../../../services/dataApi";
import { generateNotificationId, useApiCallWithNotification } from "../../utils";
import { Button } from "primereact/button";

interface IProps {
  skill: Skill;
}
const SkillsItem = ({ skill }: IProps) => {
  const notificationId = generateNotificationId();
  const dispatch = useAppDispatch();

  const submit = useApiCallWithNotification(notificationId);
  const [confirmRemoveItem, setConfirmRemoveItem] = useState(false);
  const confirmRemoveItemAction = () => setConfirmRemoveItem(true);
  const cancelConfirmRemoveItemAction = () => setConfirmRemoveItem(false);

  const removeItemAction = () => {
    dispatch(removeSkillItem(skill.id));
    submit(API_dataUpdate, "data", "skills", "Skills");
  };

  const skillUpdate = (skillName: string) => {
    dispatch(updateSkillItemElement({ ...skill, skillName }));
    submit(API_dataUpdate, "data", "skills", "Skills");
  };
  return (
    <div className="w-full mb-3 flex">
      <AppInput
        label="Skill name"
        placeholder="Enter a skill name"
        id={`skill_name_${skill.id}`}
        value={skill.skillName}
        onUpdate={skillUpdate}
        className="w-full mr-3"
      />

      {confirmRemoveItem ? (
        <div className="flex">
          <Button
            size="small"
            severity="danger"
            raised
            text
            onClick={removeItemAction}
            className="mr-2 text-xs px-2 py-1"
          >
            Confirm
            <i className="pi pi-check ml-1" style={{ fontSize: "1rem" }}></i>
          </Button>
          <Button
            size="small"
            severity="info"
            raised
            text
            onClick={cancelConfirmRemoveItemAction}
            className=" text-xs px-2 py-1"
          >
            Cancel
            <i className="pi pi-times ml-1" style={{ fontSize: "1rem" }}></i>
          </Button>
        </div>
      ) : (
        <Button icon="pi pi-trash" size="small" raised text severity="danger" onClick={confirmRemoveItemAction} />
      )}
    </div>
  );
};

export default SkillsItem;
