import {
  SET_USER,
  LOGOUT,
  User,
  UserDetails,
  UPDATE_USER_DETAILS,
  UserBilling,
  UPDATE_USER_BILLING,
} from "./types";

export const setUser = (user: Partial<User> | UserBilling) => ({
  type: SET_USER,
  payload: user,
});

export const logOut = () => ({
  type: LOGOUT,
});

export const updateUserDetails = (userDetails: Partial<UserDetails>) => ({
  type: UPDATE_USER_DETAILS,
  payload: userDetails,
});

export const updateUserBilling = (userBilling: Partial<UserBilling>) => ({
  type: UPDATE_USER_BILLING,
  payload: userBilling,
});
