import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Route, Routes } from "react-router-dom";

export type PageVisible = "signin" | "signup";

interface IProps {
  customHeader?: string;
}

const NoAuth = ({ customHeader }: IProps) => (
  <Routes>
    <Route path="/signup" element={<SignUp customHeader={customHeader} />} />
    <Route path="*" element={<SignIn customHeader={customHeader} />} />
  </Routes>
);

export default NoAuth;
