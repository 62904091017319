import { API } from "../config/constants";
import { User } from "../store/account/types";
import axios from "axios";
import "./helper";

interface UserData {
  user: User;
}

export const API_registerUser = (
  email: string,
  password: string
) =>
  axios.post<UserData>(`${API}signform`, {
    action: "register",
    email,
    password,
  });

export const API_signIn = (email: string, password: string) =>
  axios.post<UserData>(`${API}signform`, {
    action: "signin",
    email,
    password,
  });

export const API_passwordRemindRequest = (email: string) =>
  axios.post(`${API}signform`, {
    action: "password-remind-request",
    email,
  });

export const API_passwordRemindUpdate = (
  email: string,
  code: string,
  password: string
) =>
  axios.post(`${API}signform`, {
    action: "password-remind-update",
    email,
    code,
    password,
  });

// export const API_confirmRegistrationCode = (userKey: string, code: string) =>
//   submitDataToApi(`${API}signform`, {
//     action: "confirm-registration-code",
//     userKey,
//     code,
//   });
