import React from "react";
import { ToggleMenu } from "../Menu";

const Header = () => {
  return (
    <div className="flex w-full border-b border-gray-300 mb-4">
      <div className="md:hidden flex items-center mr-6">
        <ToggleMenu />
      </div>
      {/* <div className="py-2">Header!</div> */}
    </div>
  );
};

export default Header;
