import { AnyAction } from "redux";
import {
  educationItemGenerator,
  skillItemGenerator,
  workHistoryItemGenerator,
} from "./Helpers";
import {
  ADD_EDUCATION_ITEM,
  ADD_SKILL_ITEM,
  ADD_WORK_HISTORY_ITEM,
  LOAD_ALL_DATA,
  REMOVE_EDUCATION_ITEM,
  REMOVE_SKILL_ITEM,
  REMOVE_WORK_HISTORY_ITEM,
  ResumeItem,
  SELECT_TEMPLATE_ID,
  UPDATE_ADDITIONAL_INFORMATION,
  UPDATE_ALL_EDUCATION_ITEMS,
  UPDATE_ALL_SKILL_ITEMS,
  UPDATE_ALL_WORK_HISTORY_ITEMS,
  UPDATE_COVER_LETTER,
  UPDATE_EDUCATION_ITEM_ELEMENT,
  UPDATE_RESUME_ELEMENT_USER_BASE_INFO,
  UPDATE_SKILL_ITEM,
  UPDATE_WORK_HISTORY_ITEM_ELEMENT,
} from "./types";
import { LOGOUT } from "../account/types";

const initialState: ResumeItem = {
  template: {
    id: 1,
  },
  baseUserInfo: {
    firstName: "",
    lastName: "",
    profession: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    socialMediaUrl: [],
    summaryOfQualifications: "",
    improvedSummary: {
      improvedSummary: null,
      oldSummary: null,
      generationTime: null,
    },
  },
  workHistory: [],
  education: [],
  skills: [],
  additionalInformation: {
    text: "",
    improvedAdditionalInformation: {
      improvedAdditionalInfo: null,
      oldAdditionalInfo: null,
      generationTime: null,
    },
  },
  coverLetter: {
    text: "",
    improvedCoverLetter: {
      improvedCoverLetter: null,
      oldCoverLetter: null,
      generationTime: null,
    },
    template: {
      id: 1,
    },
    recipient: "",
    dateInHeader: false,
  },
};

const dataReducer = (
  state = initialState,
  { type, payload }: AnyAction
): ResumeItem => {
  switch (type) {
    case LOAD_ALL_DATA:
      return {
        ...state,
        template: { ...state.template, ...payload.template },
        baseUserInfo: { ...state.baseUserInfo, ...payload.baseUserInfo },
        workHistory: [...state.workHistory, ...payload.workHistory],
        education: [...state.education, ...payload.education],
        skills: [...state.skills, ...payload.skills],
        additionalInformation: {
          ...state.additionalInformation,
          ...payload.additionalInformation,
        },
        coverLetter: {
          ...state.coverLetter,
          ...payload.coverLetter,
        },
      };
    case SELECT_TEMPLATE_ID:
      return { ...state, template: { id: payload } };
    case UPDATE_RESUME_ELEMENT_USER_BASE_INFO:
      return { ...state, baseUserInfo: { ...state.baseUserInfo, ...payload } };

    // WORK HISTORY
    case ADD_WORK_HISTORY_ITEM:
      return {
        ...state,
        workHistory: [
          ...state.workHistory,
          workHistoryItemGenerator(state.workHistory),
        ],
      };
    case REMOVE_WORK_HISTORY_ITEM:
      return {
        ...state,
        workHistory: state.workHistory.filter((item) => item.id !== payload),
      };
    case UPDATE_WORK_HISTORY_ITEM_ELEMENT:
      return {
        ...state,
        workHistory: state.workHistory.map((item) =>
          item.id === payload.itemId ? { ...item, ...payload.element } : item
        ),
      };
    case UPDATE_ALL_WORK_HISTORY_ITEMS:
      return { ...state, workHistory: payload };

    // EDUCATION
    case ADD_EDUCATION_ITEM:
      return {
        ...state,
        education: [
          ...state.education,
          educationItemGenerator(state.education),
        ],
      };
    case REMOVE_EDUCATION_ITEM:
      return {
        ...state,
        education: state.education.filter((item) => item.id !== payload),
      };
    case UPDATE_EDUCATION_ITEM_ELEMENT:
      return {
        ...state,
        education: state.education.map((item) =>
          item.id === payload.itemId ? { ...item, ...payload.element } : item
        ),
      };
    case UPDATE_ALL_EDUCATION_ITEMS:
      return { ...state, education: payload };

    // SKILLS
    case ADD_SKILL_ITEM:
      return {
        ...state,
        skills: [...state.skills, skillItemGenerator(state.skills)],
      };
    case REMOVE_SKILL_ITEM:
      return {
        ...state,
        skills: state.skills.filter((item) => item.id !== payload),
      };
    case UPDATE_SKILL_ITEM:
      return {
        ...state,
        skills: state.skills.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case UPDATE_ALL_SKILL_ITEMS:
      return { ...state, skills: payload };
    case UPDATE_ADDITIONAL_INFORMATION:
      return {
        ...state,
        additionalInformation: {
          ...state.additionalInformation,
          ...payload,
        },
      };
    case UPDATE_COVER_LETTER:
      return {
        ...state,
        coverLetter: {
          ...state.coverLetter,
          ...payload,
        },
      };
    case LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default dataReducer;
