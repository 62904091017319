import React, { useState } from "react";
import ProfileBillingPreview from "./ProfileBillingPreview";
import ProfileBillingEdit from "./ProfileBillingEdit";
import { UserBilling } from "../../../store/account/types";

export const billingFields: { name: string; valueField: keyof UserBilling }[] =
  [
    { name: "Company name", valueField: "companyName" },
    { name: "Address", valueField: "companyAddress" },
    { name: "Address", valueField: "companyAddress2nd" },
    { name: "City", valueField: "companyCity" },
    { name: "ZIP or postal code", valueField: "companyZip" },
    { name: "State, country, province or region", valueField: "companyState" },
    { name: "Country", valueField: "companyCountryCode" },
    { name: "Tax ID", valueField: "taxId" },
  ];

const ProfileBilling = () => {
  const [edit, setEdit] = useState(false);
  const toggleEdit = () => setEdit(!edit);

  return edit ? (
    <ProfileBillingEdit toggleEdit={toggleEdit} />
  ) : (
    <ProfileBillingPreview toggleEdit={toggleEdit} />
  );
};

export default ProfileBilling;
