import { AnyAction } from "redux";
import {
  AppState,
  TOGGLE_MENU,
  ADD_UPDATE_NOTIFICATION_QUEUE_ITEM,
} from "./types";

const initialState: AppState = {
  menuOpen: false,
  notificationQueue: [],
};

const applicationReducer = (
  state = initialState,
  { type, payload }: AnyAction
): AppState => {
  switch (type) {
    case TOGGLE_MENU:
      return { ...state, menuOpen: !state.menuOpen };
    case ADD_UPDATE_NOTIFICATION_QUEUE_ITEM:
      const isNewNotification =
        state.notificationQueue.find((el) => el.id === payload.id) ===
        undefined;
      const notificationQueue = isNewNotification
        ? [...state.notificationQueue, payload].slice(-3)
        : state.notificationQueue.map((el) =>
            el.id === payload.id ? payload : el
          );
      return {
        ...state,
        notificationQueue,
      };

    default:
      return state;
  }
};

export default applicationReducer;
