import React from "react";
import { useAppSelector } from "../../store";
import NoAuth from "./NoAuth";
import Profile from "./Profile";

const Account = () => {
  const { userKey } = useAppSelector((app) => app.user.details);

  if (!userKey) return <NoAuth />;
  return <Profile />;
};

export default Account;
