import { ResumeItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import HtmlBlock from "./htmlBlock";

const styles = StyleSheet.create({
  headerBreak: {
    height: 11
  },
  date: {
    marginBottom: 5,
    marginTop: 5,
  },
  description: {
    fontWeight: 500,
    textAlign: "justify",
    color: "#444",
  },
});

const todayDate = () => {
  var date = new Date();
  const month = date.getMonth() > 8 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1);
  const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const templateCoverLetterContent = ({ coverLetter }: ResumeItem) => {
  return (
    <View>
      {coverLetter.dateInHeader && <Text style={styles.date}>{todayDate()}</Text>}

      <HtmlBlock htmlCode={coverLetter.recipient} maxWidth={420} />
      <View style={styles.headerBreak} />
      <HtmlBlock htmlCode={coverLetter.text} maxWidth={420} />
    </View>
  );
};

export default templateCoverLetterContent;
