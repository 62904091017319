import { AnyAction } from "redux";
import { LOGOUT, SET_USER, UPDATE_USER_BILLING, UPDATE_USER_DETAILS, User } from "./types";
import { DETAUL_EMPTY_USER_BILLING } from "./utils";

const initialState: User = {
  details: {
    userKey: "",
    userName: "",
    email: "",
    accountType: "standard",
  },
  billing: DETAUL_EMPTY_USER_BILLING,
};

export const userReducer = (state = initialState, action: AnyAction): User => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        details: { ...state.details, ...action.payload },
      };
    case UPDATE_USER_BILLING:
      return {
        ...state,
        billing: { ...state.billing, ...action.payload },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
