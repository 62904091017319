import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { API_signIn } from "../../services/auth";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { setUser } from "../../store/account/actions";
import { emailIsValid } from "../../utils";
import ErrorMessage from "./ErrorMessage";
import { Messages } from "primereact/messages";
import { API_dataReceiveAllData } from "../../services/dataApi";
import { loadAllData } from "../../store/data/actions";

interface IProps {
  customHeader?: string;
}

const SignIn = ({ customHeader }: IProps) => {
  const msgs = useRef<Messages>(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);

  const allowSubmit = () => {
    return password.length >= 6 && emailIsValid(email);
  };

  const signIn = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setErrorMessage("");
    setLoader(true);
    API_signIn(email, password)
      .then((response) => {
        setLoader(false);
        dispatch(setUser(response.data.user));

        // load prev saved user data
        if (msgs.current)
          msgs.current.show({
            sticky: true,
            severity: "info",
            summary: "Info",
            detail: "We restored previously entered information",
            closable: false,
          });

        API_dataReceiveAllData()
          .then((r) => {
            dispatch(loadAllData(r.data.data));
            dispatch(setUser({ billing: r.data.data.billing }));
          })
          .then(() => {
            navigate("/account");
          });
      })
      .catch((errorMessage) => {
        setLoader(false);
        setErrorMessage(errorMessage);
      });
  };

  const setupPassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);
  const setupEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  if (loader) {
    return (
      <>
        <ProgressSpinner />
        <Messages ref={msgs} />
      </>
    );
  }

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <div className="text-center mb-5">
          {customHeader ? (
            <div className="text-900 text-2xl font-medium mb-3">{customHeader}</div>
          ) : (
            <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
          )}
          <Link className="font-medium no-underline ml-2 text-blue-500 cursor-pointer" to="/signup">
            <span className="text-600 font-medium line-height-3 mr-1">Don't have an account?</span>
            Create today!
          </Link>
        </div>
        <ErrorMessage message={errorMessage} />
        <div>
          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText
            id="email"
            type="email"
            placeholder="Email address"
            className="w-full mb-3"
            required
            onChange={setupEmail}
            value={email}
          />

          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText
            id="password"
            type="password"
            placeholder="Password"
            className="w-full mb-3"
            required
            minLength={6}
            onChange={setupPassword}
            value={password}
          />

          <div className="flex align-items-center justify-content-between mb-6">
            <Link
              to="/auto/pass-reminder"
              className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer ml-auto"
            >
              Forgot your password?
            </Link>
          </div>

          <Button label="Sign In" icon="pi pi-user" className="w-full" onClick={signIn} disabled={!allowSubmit()} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
