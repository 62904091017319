import React from "react";
import { updateCoverLetter } from "../../../store/data/actions";
import { Image } from "primereact/image";
import { Button } from "primereact/button";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { useAppDispatch, useAppSelector } from "../../../store";

interface Item {
  id: number;
  preview: string;
}

const TEMPLATES = [
  { id: 1, preview: require("../../../assets/cover_letter/1.jpg") },
  { id: 2, preview: require("../../../assets/cover_letter/2.jpg") },
  { id: 3, preview: require("../../../assets/cover_letter/3.jpg") },
  { id: 4, preview: require("../../../assets/cover_letter/4.jpg") },
  { id: 5, preview: require("../../../assets/cover_letter/5.jpg") },
  { id: 6, preview: require("../../../assets/cover_letter/6.jpg") },
];

const CoverLetterTemplateSelector = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const dispatch = useAppDispatch();
  const { template } = useAppSelector((app) => app.data.coverLetter);

  const handleSelect = (e: React.MouseEvent<HTMLElement>) => {
    const id = e.currentTarget.dataset.id;
    if (!id) return;
    dispatch(
      updateCoverLetter({ template: { ...template, id: parseInt(id, 10) } })
    );

    submit(API_dataUpdate, "data", "template", "Template");
  };

  const templateItem = (item: Item) => {
    const isActive = item.id === template.id;
    return (
      <div className="mx-4 my-3 template-select-item" key={item.id}>
        <Button
          size="small"
          onClick={handleSelect}
          data-id={item.id}
          key={`template_${item.id}`}
          severity="secondary"
          text
          raised
          className={`relative ${isActive ? "border-2 border-400" : ""} `}
        >
          <Image
            src={item.preview}
            alt="Image"
            imageClassName="template-select-image"
          />

          {isActive && (
            <div className="absolute text-xs bottom-3 right-3 text-gray-500 uppercase">
              selected template
            </div>
          )}
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="uppercase text-sm font-medium mb-1">Template select</div>
      <div className="text-xs font-medium mb-3">
        New cover letter templates coming soon!
      </div>

      <div className="grid">{TEMPLATES.map(templateItem)}</div>
    </>
  );
};

export default CoverLetterTemplateSelector;
