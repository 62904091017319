import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateUserBaseInfo } from "../../../store/data/actions";
import { AppInput, AppTextarea } from "../Common";
import AddSolcialMediaButton from "./AddSolcialMediaButton";
import AddSolcialMediaSelect from "./AddSolcialMediaSelect";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { API_dataUpdate } from "../../../services/dataApi";
import { Button } from "primereact/button";
import { addUpdateNotificationQueueItem } from "../../../store/application/actions";
import { API_updateUserSummary } from "../../../services/aiApi";
import ImprovedSummary from "./ImprovedSummary";
import { Message } from "primereact/message";

const BaseUserInfo = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const [improveSummaryLoading, setImproveSummaryLoading] = useState(false);
  const [improvedSummaryText, setImprovedSummaryText] = useState("");
  const [improvedApprovedTxt, setImprovedApprovedTxt] =
    useState("");
  const [summaryTextBeforeImprovement, setSummaryTextBeforeImprovement] =
    useState("");

  const dispatch = useAppDispatch();
  const { baseUserInfo } = useAppSelector((app) => app.data);

  const handleChange = (fieldName: string) => (updatedValue: string) => {
    const value =
      fieldName === "email" ? updatedValue.toLowerCase() : updatedValue;
    dispatch(updateUserBaseInfo({ [fieldName]: value }));
    submit(API_dataUpdate, "data", "baseUserInfo", "User info");
  };

  const approveAllImprovedSummary = () => {
    handleChange("summaryOfQualifications")(improvedSummaryText);
    setImprovedSummaryText("");
    setImprovedApprovedTxt("The update has been approved");
    setTimeout(() => {
      setImprovedApprovedTxt("");
    }, 1800);
  };

  const improveSummary = () => {
    setImproveSummaryLoading(true);
    setSummaryTextBeforeImprovement(baseUserInfo.summaryOfQualifications);
    const notificationImproveSummaryId = generateNotificationId();

    dispatch(
      addUpdateNotificationQueueItem({
        id: notificationImproveSummaryId,
        elementName: "",
        status: "pending",
      })
    );

    API_updateUserSummary()
      .then((response) => response.data)
      .then((response) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveSummaryId,
            elementName: "",
            status: "success",
          })
        );
        setImprovedSummaryText(response.userSummaryImproved);
      })
      .catch((error) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveSummaryId,
            elementName: error,
            status: "failed",
          })
        );
      })
      .finally(() => {
        setImproveSummaryLoading(false);
      });
  };

  return (
    <>
      <AppInput
        label="First Name"
        id="first_name"
        value={baseUserInfo.firstName}
        isRequired
        onUpdate={handleChange("firstName")}
        className="mb-3"
      />
      <AppInput
        label="Last Name"
        id="last_name"
        value={baseUserInfo.lastName}
        isRequired
        onUpdate={handleChange("lastName")}
        className="mb-3"
      />
      <AppInput
        label="Profession"
        id="profession"
        value={baseUserInfo.profession}
        onUpdate={handleChange("profession")}
        className="mb-3"
      />
      <hr className="my-3" />
      <AppInput
        label="City"
        id="city"
        value={baseUserInfo.city}
        onUpdate={handleChange("city")}
        className="mb-3"
      />
      <div className="grid">
        <div className="col">
          <AppInput
            id="state"
            label="State/Province"
            value={baseUserInfo.state}
            onUpdate={handleChange("state")}
            className="mb-3"
          />
        </div>
        <div className="col">
          <AppInput
            id="zip_code"
            label="Zip Code"
            value={baseUserInfo.zip}
            onUpdate={handleChange("zip")}
            className="mb-3"
          />
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <div className="grid">
        <div className="col">
          <AppInput
            label="Phone"
            id="phone"
            value={baseUserInfo.phone}
            onUpdate={handleChange("phone")}
            className="mb-3"
          />
        </div>
        <div className="col">
          <AppInput
            label="E-mail Address"
            id="email"
            value={baseUserInfo.email}
            customInputType="email-address"
            onUpdate={handleChange("email")}
            className="mb-3"
          />
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <div>
        {baseUserInfo.socialMediaUrl.map((media) => (
          <AddSolcialMediaSelect key={media.id} media={media} />
        ))}
      </div>
      <div className="text-right">
        <AddSolcialMediaButton />
      </div>

      <hr className="mt-4 mb-2" />
      <div className="flex sm:flex-column md:flex-row">
        <div
          className={
            improvedSummaryText.length ? "sm:w-full md:w-6 md:mr-2" : "w-full"
          }
        >
          <AppTextarea
            onUpdate={handleChange("summaryOfQualifications")}
            value={baseUserInfo.summaryOfQualifications}
            label="About me"
            hint="Keep this section short"
            countCharactersMaxLen={900}
            isDisabled={improveSummaryLoading}
          />
        </div>
        {improvedSummaryText.length > 0 && (
          <div className="sm:w-full md:w-6 md:ml-2">
            <AppTextarea
              value={improvedSummaryText}
              label="Improved summary"
              isDisabled={true}
              noFade={true}
            />
            <div className="text-right text-sm">
              You can correct your content yourself based on the proposed
              content or replace it completely using the static below.
            </div>
            <div className="mt-2 text-right">
              <Button
                label="Approve all text"
                icon="pi pi-angle-double-left"
                className="text-sm p-2"
                onClick={approveAllImprovedSummary}
              />
            </div>
          </div>
        )}
      </div>
      {improvedApprovedTxt.length > 0 && (
        <div className="flex w-full flex-column mt-2">
          <Message severity="success" text={improvedApprovedTxt} />
        </div>
      )}
      {improvedSummaryText.length &&
      summaryTextBeforeImprovement ===
        baseUserInfo.summaryOfQualifications ? null : (
        <div className="flex flex-column justify-content-end mt-2 mb-2">
          <Button
            onClick={improveSummary}
            icon="pi pi-sort-alpha-up-alt"
            severity="secondary"
            size="small"
            raised
            text
            label="Improve my summary!"
            className="text-sm mt-2"
            loading={improveSummaryLoading}
          />
          {improveSummaryLoading && (
            <div className="text-right">
              <small>it may take ~10-15 seconds. Please be patient.</small>
            </div>
          )}
        </div>
      )}

      <ImprovedSummary improvedSummary={baseUserInfo.improvedSummary} />
    </>
  );
};

export default BaseUserInfo;
