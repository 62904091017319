import {
  ADD_EDUCATION_ITEM,
  ADD_SKILL_ITEM,
  ADD_WORK_HISTORY_ITEM,
  BaseUserInfo,
  EducationItem,
  LOAD_ALL_DATA,
  REMOVE_EDUCATION_ITEM,
  REMOVE_SKILL_ITEM,
  REMOVE_WORK_HISTORY_ITEM,
  ResumeItem,
  SELECT_TEMPLATE_ID,
  Skill,
  UPDATE_ADDITIONAL_INFORMATION,
  UPDATE_ALL_EDUCATION_ITEMS,
  UPDATE_ALL_SKILL_ITEMS,
  UPDATE_ALL_WORK_HISTORY_ITEMS,
  UPDATE_COVER_LETTER,
  UPDATE_EDUCATION_ITEM_ELEMENT,
  UPDATE_RESUME_ELEMENT_USER_BASE_INFO,
  UPDATE_SKILL_ITEM,
  UPDATE_WORK_HISTORY_ITEM_ELEMENT,
  WorkHistoryItem,
  AdditionalInformation,
  CoverLetter,
} from "./types";

export const loadAllData = (data: ResumeItem) => ({
  type: LOAD_ALL_DATA,
  payload: data,
});

export const selectTemplateId = (templateId: number) => ({
  type: SELECT_TEMPLATE_ID,
  payload: templateId,
});
export const updateUserBaseInfo = (userBaseInfo: Partial<BaseUserInfo>) => ({
  type: UPDATE_RESUME_ELEMENT_USER_BASE_INFO,
  payload: userBaseInfo,
});

export const addWorkHistoryItem = () => ({
  type: ADD_WORK_HISTORY_ITEM,
});
export const removeWorkHistoryItem = (itemId: number) => ({
  type: REMOVE_WORK_HISTORY_ITEM,
  payload: itemId,
});
export const updateWorkHistoryItemElement = (
  itemId: number,
  element: Partial<WorkHistoryItem>
) => ({
  type: UPDATE_WORK_HISTORY_ITEM_ELEMENT,
  payload: { itemId, element },
});
export const updateAllWorkHistoryItems = (items: WorkHistoryItem[]) => ({
  type: UPDATE_ALL_WORK_HISTORY_ITEMS,
  payload: items,
});

export const addEducationItem = () => ({
  type: ADD_EDUCATION_ITEM,
});
export const removeEducationItem = (itemId: number) => ({
  type: REMOVE_EDUCATION_ITEM,
  payload: itemId,
});
export const updateEducationItemElement = (
  itemId: number,
  element: Partial<EducationItem>
) => ({
  type: UPDATE_EDUCATION_ITEM_ELEMENT,
  payload: { itemId, element },
});
export const updateAllEducationItems = (items: EducationItem[]) => ({
  type: UPDATE_ALL_EDUCATION_ITEMS,
  payload: items,
});

export const addSkillItem = () => ({
  type: ADD_SKILL_ITEM,
});
export const removeSkillItem = (itemId: number) => ({
  type: REMOVE_SKILL_ITEM,
  payload: itemId,
});
export const updateSkillItemElement = (item: Skill) => ({
  type: UPDATE_SKILL_ITEM,
  payload: item,
});
export const updateAllSkillItems = (items: Skill[]) => ({
  type: UPDATE_ALL_SKILL_ITEMS,
  payload: items,
});
export const updateAdditionalInformation = (
  additionalInfo: Partial<AdditionalInformation>
) => ({
  type: UPDATE_ADDITIONAL_INFORMATION,
  payload: additionalInfo,
});
export const updateCoverLetter = (coverLetter: Partial<CoverLetter>) => ({
  type: UPDATE_COVER_LETTER,
  payload: coverLetter,
});
