import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useAppSelector } from "../../../store";
import React, { useState } from "react";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { useDispatch } from "react-redux";
import {
  updateUserBilling,
} from "../../../store/account/actions";
import { API_userDetailUpdate } from "../../../services/dataApi";
import { UserBilling } from "../../../store/account/types";
import { billingFields } from "./ProfileBilling";

interface IProps {
  toggleEdit: () => void;
}

const ProfileAboutMeEdit = ({ toggleEdit }: IProps) => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const { user } = useAppSelector((app) => app);

  const [billingInfo, setBillingInfo] = useState(user.billing);
  const updateBillingField =
    (e: React.ChangeEvent<HTMLInputElement>) => (field: keyof UserBilling) => {
      setBillingInfo({ ...billingInfo, [field]: e.target.value });
    };

  const submitData = () => {
    submit(API_userDetailUpdate, "user", "billing", "Billing information").then(
      () => {
        toggleEdit();
      }
    );
  };

  const saveChanges = () => {
    dispatch(updateUserBilling(billingInfo));
    setLoader(true);
    submitData();
  };

  const header = (
    <div className="flex p-2 align-items-center">
      <h3 className="m-0">Billing information</h3>
      <div className="ml-auto mr-2">
        {loader ? (
          <ProgressSpinner style={{ width: "30px", height: "30px" }} />
        ) : (
          <Button
            label="Save"
            icon="pi pi-user-edit"
            severity="secondary"
            size="small"
            onClick={saveChanges}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="flex">
      <Card header={header} className="w-full">
        {billingFields.map((field) => (
          <div key={field.valueField} className="mb-3">
            <p className="m-0">
              <small>{field.name}</small>
            </p>
            <p className="my-1">
              <InputText
                value={billingInfo[field.valueField]}
                placeholder="Full name"
                onChange={(e) => updateBillingField(e)(field.valueField)}
              />
            </p>
          </div>
        ))}
      </Card>
    </div>
  );
};

export default ProfileAboutMeEdit;
