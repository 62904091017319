import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";
import { API } from "../../../config/constants";
import { useAppSelector } from "../../../store";

const ProfileInfoBuyPremium = () => {
  const { billing, details } = useAppSelector((app) => app.user);

  return (
    <div className="mt-4">
      <Card title="Get Premium!" subTitle="Get more with AI!" className="w-full">
        <p>
          With a premium account, your resume can get much better! It will analyze the information you have provided and
          make it more readable and attractive to the employer.
        </p>
        <p>Check now!</p>
        <div>
          <div className="card-footer px-4 pb-4">
            <form action={`${API}payment/checkout.php`} method="POST">
              <input type="hidden" name="user_key" value={details.userKey} />
              <input type="hidden" name="email" value={details.email} />

              <input type="hidden" name="company_name" value={billing.companyName} />
              <input type="hidden" name="company_address" value={billing.companyAddress} />
              <input type="hidden" name="company_address_2nd" value={billing.companyAddress2nd} />
              <input type="hidden" name="company_city" value={billing.companyCity} />
              <input type="hidden" name="company_zip" value={billing.companyZip} />
              <input type="hidden" name="company_state" value={billing.companyState} />
              <input type="hidden" name="company_country_code" value={billing.companyCountryCode} />
              <input type="hidden" name="tax_id" value={billing.taxId} />
              <Button type="submit" label="Get Premium!" icon="pi pi-thumbs-up-fill" />
            </form>
          </div>
        </div>
      </Card>
    </div>
  );
};
export default ProfileInfoBuyPremium;
