import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { WorkHistoryItem } from "../../../store/data/types";
import { getWorkHistoryItemTitle } from "./utlis";

interface IProps {
  workHistoryItem: WorkHistoryItem;
}

const WorkHistorySummaryItem = ({ workHistoryItem }: IProps) => {
  const navigate = useNavigate();

  const openItemDetail = () => navigate(`${workHistoryItem.id}`);

  return (
    <div className="w-full mb-2">
      <Button
        severity="secondary"
        size="small"
        text
        raised
        className="w-full mb-2"
        onClick={openItemDetail}
      >
        {getWorkHistoryItemTitle(workHistoryItem)}
      </Button>
    </div>
  );
};

export default WorkHistorySummaryItem;
