import React, { useEffect, useMemo, useRef, useState } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import { NotificationQueueItem } from "../../../store/application/types";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ProgressSpinner } from "primereact/progressspinner";

const Notification = () => {
  const toast = useRef<Toast>(null);
  const dispatch = useAppDispatch();
  const [prevNotificationQueue, setPrevNotificationQueue] = useState<NotificationQueueItem[]>([]);
  const { notificationQueue } = useAppSelector((app) => app.application);
  useEffect(() => {
    if (!toast.current) return;
    setPrevNotificationQueue(notificationQueue);

    // find differences with proceeded or failed
    const queue: ToastMessage[] = notificationQueue
      .filter((notification) => {
        const prev = prevNotificationQueue.find((n) => n.id === notification.id);
        if (!prev) return false;
        if (prev.status === notification.status) return false;
        if (notification.status === "pending") return false;
        return notification;
      })
      .map((notification) => {
        const id = notification.id.toString();
        const severity = notification.status === "failed" ? "error" : "success";
        return { id, severity, detail: notification.elementName };
      });

    if (!queue.length) return;
    toast.current.show(queue);
  }, [dispatch, notificationQueue, prevNotificationQueue]);

  const hasPendingElements = useMemo(
    () => notificationQueue.find((el) => el.status === "pending") !== undefined,
    [notificationQueue]
  );

  return (
    <>
      <Toast ref={toast} />
      {hasPendingElements && <ProgressSpinner className="fixed top-0 mt-2 mr-2 right-0 w-2rem h-2rem" />}
    </>
  );
};

export default Notification;
