import React from "react";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./config/Router";
import AppInitialLogic from "./AppInitialLogic";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { MenuComponent } from "./components/Menu";
import Header from "./components/Header";
import Notification from "./components/Pages/Notification";

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <div className="grid h-full relative">
          <MenuComponent />
          <div className="relative col">
            <div className="mx-4">
              <Header />
              <Router />
              <Notification />
            </div>
          </div>
        </div>
        <AppInitialLogic />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

export default App;
