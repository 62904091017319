import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { API_registerUser } from "../../services/auth";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { emailIsValid } from "../../utils";
import { setUser } from "../../store/account/actions";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import ErrorMessage from "./ErrorMessage";

interface IProps {
  customHeader?: string;
}

const RegisterForm = ({ customHeader }: IProps) => {
  let timeout: number | undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [reenterPassword, setReenterPassword] = useState("");
  const [email, setEmail] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [fieldError, setFieldError] = useState("");

  const allowSubmit = () =>
    password.length >= 6 && emailIsValid(email) && termsAndConditions && password === reenterPassword;

  const signUp = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setErrorMessage("");
    API_registerUser(email, password)
      .then((response) => {
        dispatch(setUser(response.data.user));
        navigate("/app");
      })
      .catch((errorMessage) => {
        setErrorMessage(errorMessage);
      });
  };

  const handleFieldErrorMessage = useCallback(() => {
    if (timeout) clearTimeout(timeout);
    let message = "";
    if (reenterPassword.length === 0) message = "";
    else if (password.length < 6) message = "The password must be at least 6 characters long";
    else if (password !== reenterPassword) message = "The entered passwords are not the same";
    else if (!emailIsValid(email)) message = "The email address provided is not valid";

    if (password.length > 0 && reenterPassword.length > 0) {
      setFieldError(message);
    } else {
      setTimeout(() => {
        setFieldError(message);
      }, 2000);
    }
  }, [password, reenterPassword, email, timeout]);

  useEffect(() => {
    handleFieldErrorMessage();
  }, [password, reenterPassword, handleFieldErrorMessage]);

  const setupPassword = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

  const setupReenterPassword = (event: React.ChangeEvent<HTMLInputElement>) => setReenterPassword(event.target.value);

  const setupEmail = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

  const setupTermsAndConditions = (event: CheckboxChangeEvent) => setTermsAndConditions(event.checked || false);

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        {customHeader !== undefined && <div className="text-900 text-2xl font-medium mb-5">{customHeader}</div>}
        <ErrorMessage message={errorMessage} />
        <div>
          <label htmlFor="email" className="block text-900 font-medium mb-2">
            Email
          </label>
          <InputText
            id="email"
            type="email"
            placeholder="Email address"
            className="w-full mb-3"
            required
            onChange={setupEmail}
            value={email}
          />

          <label htmlFor="password" className="block text-900 font-medium mb-2">
            Password
          </label>
          <InputText
            id="password"
            type="password"
            placeholder="Password"
            className="w-full mb-3"
            required
            minLength={6}
            onChange={setupPassword}
            value={password}
          />

          <label htmlFor="repeat_password" className="block text-900 font-medium mb-2">
            Reenter password
          </label>
          <InputText
            id="repeat_password"
            type="password"
            placeholder="Repeat password"
            className="w-full mb-3"
            required
            minLength={6}
            onChange={setupReenterPassword}
            value={reenterPassword}
          />

          <div>
            <div className="flex align-items-center mb-3">
              <Checkbox
                inputId="terms"
                name="category"
                value="terms"
                onChange={setupTermsAndConditions}
                checked={termsAndConditions}
              />
              <label htmlFor="terms" className="ml-2">
                I've read and accept the terms & conditions.
              </label>
            </div>
          </div>

          <Button label="Sign In" icon="pi pi-user" className="w-full" onClick={signUp} disabled={!allowSubmit()} />
          {fieldError.length > 0 && <span className="p-error small">{fieldError}</span>}

          <div className="flex align-items-center justify-content-between mb-2 mt-4">
            <Link className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer ml-auto" to="/">
              Already have account?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
