import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { useAppSelector } from "../../../store";
import React from "react";

interface IProps {
  toggleEdit: () => void;
}

const ProfileAboutMePreview = ({ toggleEdit }: IProps) => {
  const { user } = useAppSelector((app) => app);

  const header = (
    <div className="flex p-2 align-items-center">
      <h3 className="m-0">About me</h3>{" "}
      <Button
        label="Edit"
        icon="pi pi-user-edit"
        severity="secondary"
        size="small"
        className="ml-auto mr-2"
        onClick={toggleEdit}
      />
    </div>
  );

  return (
      <Card
        header={header}
        className="w-full"
      >
        <div className="mb-3">
          <p className="m-0">
            <small>Full name</small>
          </p>
          <p className="my-1">
            {user.details.userName.length ? user.details.userName : "-"}
          </p>
        </div>

        <div className="mb-3">
          <p className="m-0">
            <small>E-mail</small>
          </p>
          <p className="my-1">{user.details.email}</p>
        </div>
      </Card>
  );
};

export default ProfileAboutMePreview;
