import { ResumeItem } from "../../../../store/data/types";
import templateEducationItem from "./templateEducationItem";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  education: {
    marginTop: 1,
  },
  header: {
    fontSize: 12,
  },
  marginBottom: {
    height: 15,
  },
});

const templateEeducation = ({ education }: ResumeItem) => {
  const educationNonEmpty = [...education]
    .filter((wh) => wh.schoolName.length)
    .sort((a, b) => a.order - b.order);

  if (!educationNonEmpty.length) {
    return null;
  }

  return (
    <View style={styles.education}>
      <Text style={styles.header}>Education</Text>
      {educationNonEmpty.map(templateEducationItem)}
      {educationNonEmpty.length > 0 && <View style={styles.marginBottom} />}
    </View>
  );
};

export default templateEeducation;
