import React from "react";
import { SocialMediaUrl, SocialMediaUrlType } from "../../../store/data/types";
import { useDispatch } from "react-redux";
import { updateUserBaseInfo } from "../../../store/data/actions";
import { useAppSelector } from "../../../store";
import { AppInput } from "../Common";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Button } from "primereact/button";

interface IProps {
  media: SocialMediaUrl;
}

const AddSolcialMediaSelect = ({ media }: IProps) => {
  const dispatch = useDispatch();
  const { socialMediaUrl } = useAppSelector((app) => app.data.baseUserInfo);

  const setService = (event: DropdownChangeEvent) => {
    const selectedType = event.value;
    const updatedSocialMedia = socialMediaUrl.map((s) =>
      s.id === media.id ? { ...s, type: selectedType as SocialMediaUrlType } : s
    );
    dispatch(
      updateUserBaseInfo({
        socialMediaUrl: updatedSocialMedia,
      })
    );
  };
  const setValue = (url: string) => {
    const updatedSocialMedia = socialMediaUrl.map((s) =>
      s.id === media.id ? { ...s, url: url.toLowerCase() } : s
    );
    dispatch(
      updateUserBaseInfo({
        socialMediaUrl: updatedSocialMedia,
      })
    );
  };

  const removeMedia = () =>
    dispatch(
      updateUserBaseInfo({
        socialMediaUrl: socialMediaUrl.filter((s) => s.id !== media.id),
      })
    );

  return (
    <div className="grid mb-3">
      <div className="col">
        <Dropdown
          value={media.type}
          onChange={setService}
          options={[
            { value: "linkedin", label: "Linkedin" },
            { value: "twitter", label: "Twitter" },
            { value: "website", label: "Website" },
          ]}
          placeholder="Select media"
          className="w-full"
        />
      </div>
      <div className="col">
        <AppInput
          id="media_url"
          label=""
          value={media.url}
          onUpdate={setValue}
          className="w-full mb-0"
        />
      </div>
      <div className="p-2">
        <Button
          className="flex"
          severity="secondary"
          text
          raised
          onClick={removeMedia}
          size="small" 
        >
          &#x2715;
        </Button>
      </div>
    </div>
  );
};

export default AddSolcialMediaSelect;
