
import {
  SET_PAYED,
  UPDATE_FREE_ATTEMPTS,
  UPDATE_LAST_DOWNLOAD_DATE,
  UPDATE_SUBSCRIPTION_DETAILS,
} from './types';

export const setPayed = (payed: boolean) => ({
  type: SET_PAYED,
  payload: payed,
});

export const updateFreeAttempts = (freeAttempts: number) => ({
  type: UPDATE_FREE_ATTEMPTS,
  payload: freeAttempts,
});

export const updateLastDownloadDate = () => ({
  type: UPDATE_LAST_DOWNLOAD_DATE,
});

export const updateSubscriptionDetails = (
  subscriptionDetails: any,
) => ({
  type: UPDATE_SUBSCRIPTION_DETAILS,
  payload: subscriptionDetails,
});
