import { Editor, EditorTextChangeEvent } from "primereact/editor";
import React from "react";

interface IProps {
  value: string;
  label?: string;
  hint?: string;
  countCharactersMaxLen?: number;
  isDisabled?: boolean;
  noFade?: boolean;
  onUpdate?: (updatedVal: string) => void;
}

const AppTextarea = ({ label, hint, countCharactersMaxLen, value, isDisabled, noFade, onUpdate }: IProps) => {
  const onChangeQuill = (event: EditorTextChangeEvent) => {
    if (!onUpdate) return;
    onUpdate(event.htmlValue || "");
  };

  const countCharactersInfo = () => {
    if (countCharactersMaxLen === undefined) return null;
    return (
      <div className="text-xs ml-auto">
        {value.length > countCharactersMaxLen && (
          <span className="mr-1">
            <small>Probably this section should be shorter</small>
          </span>
        )}
        <span className={value.length > countCharactersMaxLen ? "text-red-400" : ""}>{value.length}</span>/
        <small>{countCharactersMaxLen}</small>
      </div>
    );
  };

  return (
    <div className="relative">
      <div className="flex  align-items-center">
        {label && <span className="uppercase text-xs font-medium">{label}</span>}
        {hint && (
          <span className="ml-3 flex align-items-center text-xs">
            <i className="pi pi-info-circle mr-1 text-xs"></i> {hint}
          </span>
        )}
        {countCharactersInfo()}
      </div>
      <Editor
        readOnly={isDisabled}
        theme="snow"
        value={value}
        onTextChange={onChangeQuill}
        className="mt-1"
        style={{ height: "320px" }}
        modules={{
          clipboard: {
            matchVisual: false,
          },
        }}
      />
      {isDisabled && !noFade && (
        <div className="absolute top-0 left-0 w-full h-full opacity-60 flex align-items-center justify-content-center surface-200">
          <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }} />
        </div>
      )}
    </div>
  );
};

export default AppTextarea;
