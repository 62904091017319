export const TOGGLE_MENU = "TOGGLE_MENU";
export const ADD_UPDATE_NOTIFICATION_QUEUE_ITEM = "ADD_UPDATE_NOTIFICATION_QUEUE_ITEM";

export interface AppState {
  menuOpen: boolean;
  notificationQueue: NotificationQueueItem[];
}

export type NotificationQueueItemStatus = "pending" | "success" | "failed";

export interface NotificationQueueItem {
  id: string;
  elementName: string;
  status: NotificationQueueItemStatus;
}
