import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../store/account/actions";
import { ProgressSpinner } from "primereact/progressspinner";
import { persistor } from "../../store";

const Signout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logOut());
    persistor.purge().then(() => {
      localStorage.clear();
      navigate("/");
    });
  }, [dispatch, navigate]);
  return <ProgressSpinner />;
};

export default Signout;
