import { ResumeItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import HtmlBlock from "./htmlBlock";

const styles = StyleSheet.create({
  header: {
    fontSize: "12pt",
  },
  description: {
    fontWeight: 500,
    textAlign: "justify",
    color: "#444",
  },
});

const templateAdditionalInfo = ({ additionalInformation }: ResumeItem) => {
  if (!additionalInformation.text.length) {
    return null;
  }
  return (
    <View>
      <Text style={styles.header}>Additional information</Text>
      <HtmlBlock htmlCode={additionalInformation.text} maxWidth={380} />
    </View>
  );
};

export default templateAdditionalInfo;
