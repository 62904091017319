import React from "react";
import { useAppSelector } from "../../../store";
import ProfileAboutMe from "./ProfileAboutMe";
import ProfileBilling from "./ProfileBilling";
import ProfileInfoBuyPremium from "./ProfileInfoBuyPremium";

const ProfileInformation = () => {
  const { accountType } = useAppSelector((app) => app.user.details);

  return (
    <div className="flex sm:flex-column lg:flex-row">
      <div className="mr-3 sm:w-full lg:w-22rem xl:w-28rem">
        <ProfileAboutMe />
        {accountType === "standard" && <ProfileInfoBuyPremium />}
      </div>
      <div className="lg:ml-3 sm:w-full lg:w-22rem xl:w-28rem mt-4 lg:mt-0">
        <ProfileBilling />
      </div>
    </div>
  );
};

export default ProfileInformation;
