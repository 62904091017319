import { useState } from "react";
import { AppTextarea } from "../Common";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateCoverLetter } from "../../../store/data/actions";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { Button } from "primereact/button";
import { addUpdateNotificationQueueItem } from "../../../store/application/actions";
import { API_updateUserCoverLetter } from "../../../services/aiApi";
import ImprovedCoverLetterContainer from "./ImprovedCoverLetter";
import Recipient from "./Recipient";
import IncDateInHeaderToggle from "./IncDateInHeaderToggle";
import React from "react";
import { Message } from "primereact/message";

const CoverLetter = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);
  const [improvedCoverLetterLoading, setImprovedCoverLetterLoading] =
    useState(false);
  const [improvedCoverLetterText, setImprovedCoverLetterText] = useState("");
  const [
    improvedCoverLetterTextBeforeImprovement,
    setImprovedCoverLetterTextBeforeImprovement,
  ] = useState("");
  const [improvedApprovedTxt, setImprovedApprovedTxt] = useState("");

  const { coverLetter } = useAppSelector((app) => app.data);

  const dispatch = useAppDispatch();

  const submitData = () => {
    submit(API_dataUpdate, "data", "coverLetter", "Cover letter");
  };

  const handleChange = (updatedTxt: string) => {
    dispatch(updateCoverLetter({ text: updatedTxt }));
    submitData();
  };

  const approveAllImprovedCoverLetter = () => {
    dispatch(updateCoverLetter({ text: improvedCoverLetterText }));
    submitData();
    setImprovedCoverLetterText("");
    setImprovedApprovedTxt("The update has been approved");
    setTimeout(() => {
      setImprovedApprovedTxt("");
    }, 1800);
  };

  const generateOrImproveCoverLetter = () => {
    setImprovedCoverLetterLoading(true);
    setImprovedCoverLetterTextBeforeImprovement(coverLetter.text);
    const notificationImproveCoverLetterId = generateNotificationId();

    dispatch(
      addUpdateNotificationQueueItem({
        id: notificationImproveCoverLetterId,
        elementName: "",
        status: "pending",
      })
    );

    API_updateUserCoverLetter()
      .then((response) => response.data)
      .then((response) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveCoverLetterId,
            elementName: "",
            status: "success",
          })
        );
        setImprovedCoverLetterText(response.improvedCoverLetter);
      })
      .catch((error) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveCoverLetterId,
            elementName: error,
            status: "failed",
          })
        );
      })
      .finally(() => {
        setImprovedCoverLetterLoading(false);
      });
  };

  return (
    <>
      <h6 className="mb-1">
        1. Should a cover letter include a date in the header?
      </h6>
      <IncDateInHeaderToggle />
      <h6 className="mb-1">2. Please add recipient</h6>
      <Recipient />

      <h6 className="mb-1">3. Write cover letter</h6>
      <div className="flex sm:flex-column md:flex-row">
        <div
          className={
            improvedCoverLetterText.length
              ? "sm:w-full md:w-6 md:mr-2"
              : "w-full"
          }
        >
          <AppTextarea
            onUpdate={handleChange}
            value={coverLetter.text}
            isDisabled={improvedCoverLetterLoading}
          />
        </div>
        {improvedCoverLetterText.length > 0 && (
          <div className="sm:w-full md:w-6 md:ml-2">
            <AppTextarea
              value={improvedCoverLetterText}
              label="Improved summary"
              isDisabled={true}
              noFade={true}
            />
            <div className="text-right text-sm">
              You can correct your content yourself based on the proposed
              content or replace it completely using the static below.
            </div>
            <div className="mt-2 text-right">
              <Button
                label="Approve all text"
                icon="pi pi-angle-double-left"
                className="text-sm p-2"
                onClick={approveAllImprovedCoverLetter}
              />
            </div>
          </div>
        )}
      </div>
      {improvedApprovedTxt.length > 0 && (
        <div className="flex w-full flex-column mt-2">
          <Message severity="success" text={improvedApprovedTxt} />
        </div>
      )}

      {improvedCoverLetterText.length &&
      improvedCoverLetterTextBeforeImprovement === coverLetter.text ? null : (
        <div className="flex flex-column justify-content-end mt-2 mb-2">
          <Button
            onClick={generateOrImproveCoverLetter}
            icon="pi pi-sort-alpha-up-alt"
            severity="secondary"
            size="small"
            raised
            text
            label="Improve cover letter"
            className="text-sm mt-2"
            loading={improvedCoverLetterLoading}
          />
          <div className="mt-2 line-height-1">
            <small>
              Hint: if you want, you can complete the above data and press
              "Improve cover letter"
              <br />
              <u>However</u>, you can leave it blank - then we will generate
              them for you based on other data such as education or work
              experience.
            </small>
          </div>
        </div>
      )}

      <ImprovedCoverLetterContainer
        improvedCoverLetter={coverLetter.improvedCoverLetter}
      />
    </>
  );
};

export default CoverLetter;
