import { useEffect } from "react";
import { FREE_PDF_DOWNLOADS_WEEKLY } from "./constants";
import { useAppDispatch, useAppSelector } from "./store";
import {
  updateFreeAttempts,
  updateLastDownloadDate,
} from "./store/payment/actions";

const daysAgo = (date_1: Date, date_2: Date) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
};

const AppInitialLogic = () => {
  const dispatch = useAppDispatch();
  const { lastDownloadDate } = useAppSelector((app) => app.payment);
  useEffect(() => {
    const lastDownloadDay = new Date(lastDownloadDate).getDay();
    const currentDay = new Date().getDay();
    if (lastDownloadDay > currentDay) {
      dispatch(updateFreeAttempts(FREE_PDF_DOWNLOADS_WEEKLY));
      dispatch(updateLastDownloadDate());
      return;
    }

    const dateNow = new Date();
    const dateThen = new Date(lastDownloadDate);
    if (daysAgo(dateNow, dateThen) >= 7) {
      dispatch(updateFreeAttempts(FREE_PDF_DOWNLOADS_WEEKLY));
      dispatch(updateLastDownloadDate());
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default AppInitialLogic;
