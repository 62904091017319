import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import dataReducer from "./data/reducers";
import paymentReducer from "./payment/reducers";
import persistStore from "redux-persist/es/persistStore";
import applicationReducer from "./application/reducers";
import userReducer from "./account/reducers";

const persistConfig = {
  key: "resume",
  storage,
  blacklist: ["application"],
};

const rootReducer = combineReducers({
  application: applicationReducer,
  data: dataReducer,
  payment: paymentReducer,
  user: userReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();
