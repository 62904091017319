import { ResumeItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";

/*

  important issue: 
  https://github.com/diegomura/react-pdf/issues/2195

  thats because of render
*/

const styles = StyleSheet.create({
  sidebar: {
    marginLeft: 5,
    marginRight: 5,
  },
  sideBlockTop: {
    marginTop: 5,
    borderWidth:2,borderColor:'green',borderStyle: 'solid'
  },
  header: {
    marginBottom: 3,
    fontSize: 11,
    color: "#444",
  },
  profession: {
    marginBottom: 3,
    fontSize: 8,
    color: "#444",
  },
  personalInfo: {
    marginBottom: 4,
    paddingBottom: 2,
    borderBottomColor: "#999",
    borderBottomWidth: 0.5,
    borderBottomStyle: "solid",
    fontSize: 11,
    color: "#444",
  },
  contactInfoBlock: {
    marginBottom: 10,
    borderWidth:2,borderColor:'red',borderStyle: 'solid'
  },
  listItem: {
    marginLeft: 2,
    fontSize: 9,
    marginBottom: 5,
    color: "#444",
    fontWeight: "normal",
  },
  headerBreak: {
    height: 10
  }
});

const templateSidebar = (
  { baseUserInfo }: ResumeItem,
  showBaseUserInfo: boolean = true
) => {
  return (
    <View style={styles.sidebar}>
      <View style={styles.sideBlockTop}>
        {showBaseUserInfo && (
          <>
            <Text style={styles.header}>
              {baseUserInfo.firstName} {baseUserInfo.lastName}
            </Text>
            <Text style={styles.profession}>{baseUserInfo.profession}</Text>
            <View style={styles.headerBreak} />
          </>
        )}
        
        <Text style={styles.personalInfo}>Personal Info</Text>

        <View style={styles.contactInfoBlock}>
          {baseUserInfo.phone.toString().length > 0 && (
            <Text style={styles.listItem}>Phone: {baseUserInfo.phone}</Text>
          )}
          {baseUserInfo.email.length > 0 && (
            <Text style={styles.listItem}>E-mail: {baseUserInfo.email}</Text>
          )}
          {baseUserInfo.socialMediaUrl.map((social) => (
            <Text key={`social_${social.id}`} style={styles.listItem}>
              {social.url}
            </Text>
          ))}
        </View>
      </View>
    </View>
  );
};

export default templateSidebar;
