import { EducationItem } from "../../../../store/data/types";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import React from "react";
import HtmlBlock from "./htmlBlock";
import { educationById, renderTimePeriod } from "./templateHelper";

const styles = StyleSheet.create({
  base: {
    display: "flex",
    flexDirection: "row",
  },
  header: {
    fontSize: "12pt",
  },
  date: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontSize: 7,
    lineHeight: 1,
    width: 35,
    paddingTop: 5,
  },
  jobPlace: {},
  datePresent: {},
  jobTitle: {
    fontSize: "9pt",
  },
  employer: {
    fontSize: "7pt",
  },
  location: {},
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignContent: "center",
    borderLeftWidth: 1,
    borderColor: "#e4e4e4",
    borderStyle: "solid",
    paddingLeft: 5,
    paddingTop: 5,
    position: "relative",
  },
  jobDescription: {
    padding: 0,
    margin: 0,
  },
  dot: {
    position: "absolute",
    width: 5,
    height: 5,
    backgroundColor: "#e4e4e4",
    top: 8,
    left: -3,
    borderRadius: "50%",
  },
});

const templateEducationItem = ({
  schoolName,
  schoolLocation,
  degree,
  customDegree,
  fieldOfStudy,
  graduationStartMonth,
  graduationStartYear,
  graduationEndMonth,
  graduationEndYear,
  stillAttendHere,
  schoolDescription,
}: EducationItem) => (
  <View style={styles.base} key={`education_${schoolName}${schoolLocation}}`}>
    <View style={styles.date}>
      <Text>{renderTimePeriod(graduationStartMonth, graduationStartYear)}</Text>
      {stillAttendHere && <Text style={styles.datePresent}>present</Text>}
      {!stillAttendHere && (
        <Text>{renderTimePeriod(graduationEndMonth, graduationEndYear)}</Text>
      )}
    </View>
    <View style={styles.content}>
      <View style={styles.dot} />
      <View style={styles.jobPlace}>
        {degree === "15" && customDegree.length > 0 && (
          <Text style={styles.jobTitle}>{customDegree}</Text>
        )}
        {degree !== "15" && (
          <Text style={styles.jobTitle}>
            {educationById(parseInt(degree, 10))}
          </Text>
        )}
        {fieldOfStudy.length > 0 && (
          <Text style={styles.jobTitle}>{fieldOfStudy}</Text>
        )}

        {(schoolName.length > 0 || schoolLocation.length > 0) && (
          <Text style={styles.location}>
            {schoolName.length > 0 && schoolName}
            {schoolName.length > 0 && schoolLocation.length > 0 && " "}
            {schoolLocation.length > 0 && schoolLocation}
          </Text>
        )}
      </View>
      {schoolDescription.length > 0 && (
        <View>
          <HtmlBlock htmlCode={schoolDescription} maxWidth={370} />
        </View>
      )}
    </View>
  </View>
);

export default templateEducationItem;
