import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useAppSelector } from "../../../store";
import React, { useState } from "react";
import { emailIsValid } from "../../../utils";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";
import { useDispatch } from "react-redux";
import { updateUserDetails } from "../../../store/account/actions";
import { API_userDetailUpdate } from "../../../services/dataApi";

interface IProps {
  toggleEdit: () => void;
}

const ProfileAboutMeEdit = ({ toggleEdit }: IProps) => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const { user } = useAppSelector((app) => app);

  const [userName, setUserName] = useState(user.details.userName);
  const changeUserName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(e.target.value);
  };

  const [email, setEmail] = useState(user.details.email);
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const isEmailValid = emailIsValid(email);

  const submitData = () => {
    submit(API_userDetailUpdate, "user", "details", "About me").then(() => {
      toggleEdit();
    });
  };

  const saveChanges = () => {
    dispatch(
      updateUserDetails({
        userName,
        email,
      })
    );
    setLoader(true);
    submitData();
  };

  const header = (
    <div className="flex p-2 align-items-center">
      <h3 className="m-0">About me</h3>
      <div className="ml-auto mr-2">
        {loader ? (
          <ProgressSpinner style={{ width: "30px", height: "30px" }} />
        ) : (
          <Button
            label="Save"
            icon="pi pi-user-edit"
            severity="secondary"
            size="small"
            onClick={saveChanges}
            disabled={!isEmailValid}
          />
        )}
      </div>
    </div>
  );

  return (
    <Card header={header} className="w-full">
      <div className="mb-3">
        <p className="m-0">
          <small>Full name</small>
        </p>
        <p className="my-1">
          <InputText
            value={userName}
            placeholder="Full name"
            onChange={changeUserName}
          />
        </p>
      </div>

      <div className="mb-3">
        <p className="m-0">
          <small>E-mail</small>
        </p>
        <p className="my-1">
          <InputText
            value={email}
            placeholder="E-mail"
            onChange={changeEmail}
            required
            className={!isEmailValid ? "p-invalid" : ""}
          />
          {!isEmailValid && (
            <div className="text-red-600">
              <small>E-mail is invalid</small>
            </div>
          )}
        </p>
        <p className="my-0"><small>Remember that this email address is used to log in!</small></p>
      </div>
    </Card>
  );
};

export default ProfileAboutMeEdit;
