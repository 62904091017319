import { useAppSelector, useAppDispatch } from "../../../store";
import {
  addWorkHistoryItem,
  updateAllWorkHistoryItems,
} from "../../../store/data/actions";
import WorkHistorySummaryItem from "./WorkHistorySummaryItem";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { Button } from "primereact/button";
import React from "react";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";

const WorkHistory = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const dispatch = useAppDispatch();
  const { workHistory } = useAppSelector((app) => app.data);

  const addNewWorkHistoryItem = () => dispatch(addWorkHistoryItem());

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updatedSkills = arrayMoveImmutable(
      workHistory,
      oldIndex,
      newIndex
    ).map((skill, order) => ({ ...skill, order }));
    dispatch(updateAllWorkHistoryItems(updatedSkills));
    submit(API_dataUpdate, "data", "workHistory", "Work history");
  };

  return (
    <div>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {workHistory.map((item) => (
          <SortableItem key={item.id}>
            <div className="item flex items-center justify-center">
              <WorkHistorySummaryItem workHistoryItem={item} />
              <SortableKnob>
                <div className="flex ml-3 mb-2 align-items-center cursor-pointer">
                  <i className="pi pi-sort" />
                </div>
              </SortableKnob>
            </div>
          </SortableItem>
        ))}
      </SortableList>
      <div className="mb-2 mt-3 text-sm">
        {workHistory.length
          ? "Press the button below to add more items"
          : "Press the button below to add first item"}
      </div>
      <Button
        size="small"
        severity="secondary"
        text
        raised
        onClick={addNewWorkHistoryItem}
      >
        &#43; Add work history
      </Button>
    </div>
  );
};

export default WorkHistory;
