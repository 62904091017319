import axios from "axios";
import "./helper";
import { API } from "../config/constants";
import {
  BaseUserInfo,
  ImprovedAdditionalInformation,
  ImprovedCoverLetter,
  ImprovedSummaryResumeItem,
  ResumeItem,
} from "../store/data/types";
import { UserBilling } from "../store/account/types";

export const API_dataUpdate = (data: object) =>
  axios.post<{ preview: string[] }>(
    `${API}user-data-update`,
    { ...data, action: "update" },
    {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }
  );

export const API_userDetailUpdate = (data: object) =>
  axios.post<{ preview: string[] }>(
    `${API}user-data-update`,
    { ...data, action: "user-data-update" },
    {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }
  );

export const API_dataReceiveAllData = () =>
  axios.post<{ data: ResumeItem & { billing: UserBilling } }>(
    `${API}user-data-update`,
    {
      action: "receive-all",
    },
    {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }
  );

export const API_dataBaseUserInfo = () =>
  axios.post<{ details: BaseUserInfo }>(
    `${API}user-data-update`,
    {
      action: "base-user-info",
    },
    {
      headers: {
        "Content-Type": `multipart/form-data`,
      },
    }
  );

export const API_getLastUpdatedUserSummary = () =>
  axios.post<ImprovedSummaryResumeItem>(`${API}user-data-update`, {
    action: "last_improved_user_summary",
  });
export const API_getLastUpdatedAdditionalInformation = () =>
  axios.post<ImprovedAdditionalInformation>(`${API}user-data-update`, {
    action: "last_improved_additional_information",
  });
export const API_getLastUpdatedCoverLetter = () =>
  axios.post<ImprovedCoverLetter>(`${API}user-data-update`, {
    action: "last_improved_cover_letter",
  });
