import { useState } from "react";
import { AppTextarea } from "../Common";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateAdditionalInformation } from "../../../store/data/actions";
import { API_dataUpdate } from "../../../services/dataApi";
import { generateNotificationId, useApiCallWithNotification } from "../../utils";
import { Button } from "primereact/button";
import { addUpdateNotificationQueueItem } from "../../../store/application/actions";
import { API_updateUserAdditionalInformation } from "../../../services/aiApi";
import ImprovedAdditionalInformationContainer from "./ImprovedAdditionalInformation";
import { Link } from "react-router-dom";
import React from "react";
import { Message } from "primereact/message";

const AdditionalInformation = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);
  const [improvedSummaryLoading, setImprovedSummaryLoading] = useState(false);
  const [improvedSummaryText, setImprovedSummaryText] = useState("");
  const [improvedApprovedTxt, setImprovedApprovedTxt] = useState("");
  const [improvedSummaryTextBeforeImprovement, setImprovedSummaryTextBeforeImprovement] = useState("");

  const { additionalInformation } = useAppSelector((app) => app.data);

  const dispatch = useAppDispatch();

  const handleChange = (updatedTxt: string) => {
    dispatch(updateAdditionalInformation({ text: updatedTxt }));
    submit(API_dataUpdate, "data", "additionalInformation", "Additional information about the user");
  };

  const approveAllImprovedSummary = () => {
    handleChange(improvedSummaryText);
    setImprovedSummaryText("");
    setImprovedApprovedTxt("The update has been approved");
    setTimeout(() => {
      setImprovedApprovedTxt("");
    }, 1800);
  };

  const generateOrImproveAdditionalInformation = () => {
    setImprovedSummaryLoading(true);
    setImprovedSummaryTextBeforeImprovement(additionalInformation.text);
    const notificationImproveSummaryId = generateNotificationId();

    dispatch(
      addUpdateNotificationQueueItem({
        id: notificationImproveSummaryId,
        elementName: "",
        status: "pending",
      })
    );

    API_updateUserAdditionalInformation()
      .then((response) => response.data)
      .then((response) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveSummaryId,
            elementName: "",
            status: "success",
          })
        );
        setImprovedSummaryText(response.improvedAdditionalInformation);
      })
      .catch((error) => {
        dispatch(
          addUpdateNotificationQueueItem({
            id: notificationImproveSummaryId,
            elementName: error,
            status: "failed",
          })
        );
      })
      .finally(() => {
        setImprovedSummaryLoading(false);
      });
  };

  return (
    <>
      <div>
        Additional information section contains important information about your skills, qualifications or
        characteristics that will complement and briefly summarize your person. Write what kind of person you are, what
        you are interested in and what your passions are. This part will certainly allow you to stand out from other
        candidates.
      </div>
      <div className="my-3 text-xs">
        <i className="pi pi-info-circle mr-1 text-xs" /> Important: this is not a cover letter. To generate a cover
        letter, <Link to="/cover-letter">go to this section</Link>.
      </div>

      <div className="flex sm:flex-column md:flex-row">
        <div className={improvedSummaryText.length ? "sm:w-full md:w-6 md:mr-2" : "w-full"}>
          <AppTextarea
            onUpdate={handleChange}
            value={additionalInformation.text}
            label="Additional Information"
            isDisabled={improvedSummaryLoading}
          />
        </div>
        {improvedSummaryText.length > 0 && (
          <div className="sm:w-full md:w-6 md:ml-2">
            <AppTextarea
              value={improvedSummaryText}
              label="Improved Additional Information"
              isDisabled={true}
              noFade={true}
            />
            <div className="text-right text-sm">
              You can correct your content yourself based on the proposed content or replace it completely using the
              static below.
            </div>
            <div className="mt-2 text-right">
              <Button
                label="Approve all text"
                icon="pi pi-angle-double-left"
                className="text-sm p-2"
                onClick={approveAllImprovedSummary}
              />
            </div>
          </div>
        )}
      </div>
      {improvedApprovedTxt.length > 0 && (
        <div className="flex w-full flex-column mt-2">
          <Message severity="success" text={improvedApprovedTxt} />
        </div>
      )}

      {improvedSummaryText.length && improvedSummaryTextBeforeImprovement === additionalInformation.text ? null : (
        <div className="flex flex-column justify-content-end mt-2 mb-2">
          <Button
            onClick={generateOrImproveAdditionalInformation}
            icon="pi pi-sort-alpha-up-alt"
            severity="secondary"
            size="small"
            raised
            text
            label="Improve additional information"
            className="text-sm mt-2"
            loading={improvedSummaryLoading}
          />
          <div className="mt-2 line-height-1">
            <small>
              Hint: if you want, you can complete the above data and press "Improve additional information"
              <br />
              <u>However</u>, you can leave it blank - then we will generate them for you based on other data such as
              education or work experience.
            </small>
          </div>
        </div>
      )}

      <ImprovedAdditionalInformationContainer
        improvedAdditionalInformation={additionalInformation.improvedAdditionalInformation}
      />
    </>
  );
};

export default AdditionalInformation;
