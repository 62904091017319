import { UserBilling } from "./types";

export const DETAUL_EMPTY_USER_BILLING: UserBilling = {
  companyName: "",
  companyAddress: "",
  companyAddress2nd: "",
  companyCity: "",
  companyZip: "",
  companyState: "",
  companyCountryCode: "",
  taxId: "",
};
