import {
  ADD_UPDATE_NOTIFICATION_QUEUE_ITEM,
  NotificationQueueItem,
  TOGGLE_MENU
} from "./types";

export const toggleMenu = () => ({
  type: TOGGLE_MENU,
});

export const addUpdateNotificationQueueItem = (
  notificationItem: NotificationQueueItem
) => ({
  type: ADD_UPDATE_NOTIFICATION_QUEUE_ITEM,
  payload: notificationItem,
});