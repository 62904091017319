import React from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  addEducationItem,
  updateAllEducationItems,
} from "../../../store/data/actions";
import EducationSummaryItem from "./EducationSummaryItem";
import { arrayMoveImmutable } from "array-move";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { Button } from "primereact/button";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";

const Education = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const dispatch = useAppDispatch();
  const { education } = useAppSelector((app) => app.data);
  const addNewEducationItem = () => dispatch(addEducationItem());

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updatedItems = arrayMoveImmutable(education, oldIndex, newIndex).map(
      (skill, order) => ({ ...skill, order })
    );
    dispatch(updateAllEducationItems(updatedItems));
    submit(API_dataUpdate, "data", "education", "Education");
  };

  return (
    <div>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {education.map((skill) => (
          <SortableItem key={skill.id}>
            <div className="item flex items-center justify-center">
              <EducationSummaryItem
                key={`edu_${skill.id}`}
                educationItem={skill}
              />
              <SortableKnob>
                <div className="flex ml-3 mb-2 align-items-center cursor-pointer">
                  <i className="pi pi-sort" />
                </div>
              </SortableKnob>
            </div>
          </SortableItem>
        ))}
      </SortableList>
      <div className="mb-2 mt-3 text-sm">
        {education.length
          ? "Press the button below to add more items"
          : "Press the button below to add first item"}
      </div>
      <Button
        size="small"
        severity="secondary"
        text
        raised
        onClick={addNewEducationItem}
      >
        &#43; Add education item
      </Button>
    </div>
  );
};

export default Education;
