import { ResumeItem } from "../../../../store/data/types";
import templateSidebar from "./templateSidebar";
import { Page, View, Document, StyleSheet, Font, Text } from "@react-pdf/renderer";
import templateCoverLetterContent from "./templateCoverLetterContent";
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: require("../../../../assets/roboto/Roboto-Light.ttf"),
      fontWeight: "light",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-LightItalic.ttf"),
      fontWeight: "light",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Regular.ttf"),
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-Italic.ttf"),
      fontWeight: "normal",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Bold.ttf"),
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-BoldItalic.ttf"),
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    display: "flex",
    margin: 0,
    fontWeight: "normal",
    lineHeight: 1.3,
    color: "#444",
    hyphens: "auto",
    fontSize: "8pt",
    fontFamily: "Roboto",
  },
  sectionMain: {
    marginTop: "0.1in",
    marginLeft: 10,
    marginRight: 15,
    width: 430,
    fontSize: "8pt",
    paddingBottom: 10,
  },
  sectionSidebar: {
    position: "relative" /* for disclaimer */,
    height: "100%",
    backgroundColor: "#fff",
    borderLeftStyle: "solid",
    borderLeftColor: "#f2f2f2",
    borderLeftWidth: 0,
    width: 140,
  },
  bottomPart: {
    display: "flex",
    flexDirection: "row",
  },
  headerPart: {
    marginLeft: 10,
    marginBottom: 10,
  },
  headerPartName: {
    fontSize: 14,
  },
  headerPartProfession: {
    fontSize: 10,
  },
});

const Template_6 = (item: ResumeItem) => (
  <Document>
    <Page size="A4" style={{ ...styles.page, ...styles.sectionMain }} wrap>
      <View style={styles.headerPart}>
        <Text style={styles.headerPartName}>
          {item.baseUserInfo.firstName} {item.baseUserInfo.lastName}
        </Text>
        <Text style={styles.headerPartProfession}>{item.baseUserInfo.profession}</Text>
      </View>

      <View style={styles.bottomPart}>
        <View style={styles.sectionMain}>
          <View
            fixed
            render={({ pageNumber }) =>
              pageNumber === 1 ? null : (
                <View
                  style={{
                    height: 20,
                  }}
                />
              )
            }
          ></View>
          {templateCoverLetterContent(item)}

          <View
            fixed
            render={() => (
              <View
                style={{
                  height: 20,
                }}
              />
            )}
          ></View>
        </View>

        <View
          style={styles.sectionSidebar}
          fixed
          render={({ pageNumber }) => <View key={pageNumber}>{pageNumber === 1 && templateSidebar(item, false)}</View>}
        />
      </View>
    </Page>
  </Document>
);

export default Template_6;
