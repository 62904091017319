

export const SET_PAYED = 'SET_PAYED';
export const UPDATE_FREE_ATTEMPTS = 'UPDATE_FREE_ATTEMPTS';
export const UPDATE_LAST_DOWNLOAD_DATE = 'UPDATE_LAST_DOWNLOAD_DATE';
export const UPDATE_SUBSCRIPTION_DETAILS = 'UPDATE_SUBSCRIPTION_DETAILS';

export interface PaymentState {
  payed: boolean;
  freeAttempts: number;
  lastDownloadDate: Date;
  subscriptionDetails?: any;
}
