import React from "react";
import SkillsItem from "./SkillsItem";
import { useAppDispatch, useAppSelector } from "../../../store";
import { addSkillItem, updateAllSkillItems } from "../../../store/data/actions";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import { Button } from "primereact/button";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";

const Skills = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  const dispatch = useAppDispatch();
  const { skills } = useAppSelector((app) => app.data);

  const addNewSkill = () => dispatch(addSkillItem());

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const updatedSkills = arrayMoveImmutable(skills, oldIndex, newIndex).map(
      (skill, order) => ({ ...skill, order })
    );
    dispatch(updateAllSkillItems(updatedSkills));
    submit(API_dataUpdate, "data", "skills", "Skills");
  };

  return (
    <div>
      <div className="uppercase text-sm font-medium mb-3">
        Skills to highlight
      </div>
      <SortableList
        onSortEnd={onSortEnd}
        className="list"
        draggedItemClassName="dragged"
      >
        {skills.map((skill) => (
          <SortableItem key={skill.id}>
            <div className="item flex items-center justify-center">
              <SkillsItem skill={skill} />
              <SortableKnob>
                <div className="flex ml-3 mb-2 align-items-center cursor-pointer">
                  <i className="pi pi-sort" />
                </div>
              </SortableKnob>
            </div>
          </SortableItem>
        ))}
      </SortableList>

      <Button
        severity="secondary"
        size="small"
        text
        raised
        onClick={addNewSkill}
      >
        &#43; Add skill item
      </Button>
    </div>
  );
};

export default Skills;
