import { WorkHistoryItem } from "../../../store/data/types";

export const getWorkHistoryItemTitle = (workHistoryItem: WorkHistoryItem) => {
  let itemTitle = workHistoryItem.jobTitle;
  if (
    workHistoryItem.jobTitle.length > 0 &&
    workHistoryItem.employer.length > 0
  )
    itemTitle += ", ";
  itemTitle += workHistoryItem.employer;

  // if no length then default
  if (itemTitle.length < 2) {
    itemTitle = `Work item ${workHistoryItem.id}`;
  }
  return itemTitle;
};
