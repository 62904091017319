import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import AdditionalInformation from "../components/Pages/AdditionalInformation";
import BaseUserInfo from "../components/Pages/BaseUserInfo";
import { Education, EducationDetail } from "../components/Pages/Education";
import Skills from "../components/Pages/Skills";
import DownloadResume from "../components/Pages/DownloadResume";
import { WorkHistory, WorkHistoryDetail } from "../components/Pages/WorkHistory";
import TemplateSelector from "../components/TemplateSelector";
import { useAppSelector } from "../store";
import Account from "../components/Account";
import Signout from "../components/Account/Signout";
import NoAuth from "../components/Account/NoAuth";
import CoverLetter from "../components/Pages/CoverLetter/CoverLetter";
import CoverLetterTemplateSelector from "../components/Pages/CoverLetterTemplateSelector/CoverLetterTemplateSelector";
import DownloadCoverLetter from "../components/Pages/DownloadCoverLetter/DownloadCoverLetter";
import PaymentResult from "../components/Pages/PaymentResult";

function Redirect({ to }: { to: string }) {
  let navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  });
  return null;
}

const Router = () => {
  const {
    user: { details },
  } = useAppSelector((app) => app);

  if (!details.userKey) {
    return (
      <NoAuth customHeader="In order not to lose your data, please log in or register. It will take you no more than 10 seconds!" />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Redirect to="/account" />} />
      <Route path="/payment" element={<PaymentResult />} />
      <Route path="/user-info" element={<BaseUserInfo />} />
      <Route path="/work-history" element={<WorkHistory />} />
      <Route path="/work-history/:id" element={<WorkHistoryDetail />} />
      <Route path="/education" element={<Education />} />
      <Route path="/education/:id" element={<EducationDetail />} />
      <Route path="/skills" element={<Skills />} />
      <Route path="/additional-information" element={<AdditionalInformation />} />
      <Route path="/cover-letter" element={<CoverLetter />} />
      <Route path="/cover-letter-template" element={<CoverLetterTemplateSelector />} />
      <Route path="/cover-letter-download" element={<DownloadCoverLetter />} />
      <Route path="/template-select" element={<TemplateSelector />} />
      <Route path="/download-resume" element={<DownloadResume />} />
      <Route path="/account" element={<Account />} />
      <Route path="/signout" element={<Signout />} />
    </Routes>
  );
};

export default Router;
