import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateEducationItemElement } from "../../../store/data/actions";
import { EDUCATION_DEGREES, MONTHS, YEARS } from "../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { AppInput, AppTextarea } from "../Common";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import React from "react";
import { API_dataUpdate } from "../../../services/dataApi";
import {
  generateNotificationId,
  useApiCallWithNotification,
} from "../../utils";

const EducationDetail = () => {
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  let id = parseInt(useParams().id || "0");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { education } = useAppSelector((app) => app.data);
  const currentEducationItem = education.find((item) => item.id === id);

  useEffect(() => {
    if (!currentEducationItem) navigate("/education");
  }, [currentEducationItem, navigate]);

  if (!currentEducationItem) return null;

  const handleChange =
    (fieldName: string) => (updatedValue: string | number | boolean) => {
      dispatch(updateEducationItemElement(id, { [fieldName]: updatedValue }));
      submit(API_dataUpdate, "data", "education", "Education");
    };
  const dropdownChange = (field: string) => (event: DropdownChangeEvent) =>
    handleChange(field)(event.value);
  const checkboxChange = (field: string) => (event: CheckboxChangeEvent) =>
    handleChange(field)(event.checked || false);

  return (
    <>
      <AppInput
        label="School name"
        id="school_name"
        key={`school_name_${id}`}
        value={currentEducationItem.schoolName}
        isRequired
        onUpdate={handleChange("schoolName")}
        className="mb-3"
      />
      <AppInput
        label="School location"
        id="school_location"
        key={`school_location_${id}`}
        value={currentEducationItem.schoolLocation}
        onUpdate={handleChange("schoolLocation")}
        className="mb-3"
      />
      <hr className="my-4" />
      <Dropdown
        key={`degree_${id}`}
        value={currentEducationItem.degree}
        onChange={dropdownChange("degree")}
        options={EDUCATION_DEGREES.map((m) => ({
          label: m.label,
          value: m.id.toString(),
        }))}
        placeholder="Degree"
        className="w-full mb-3"
      />
      {currentEducationItem.degree === "15" && (
        <AppInput
          label="Enter a different degree"
          id="different_degree"
          key={`different_degree_${id}`}
          value={currentEducationItem.customDegree}
          onUpdate={handleChange("customDegree")}
          className="mb-3"
        />
      )}
      <AppInput
        label="Field of study"
        id="field_of_study"
        key={`field_of_study_${id}`}
        value={currentEducationItem.fieldOfStudy}
        onUpdate={handleChange("fieldOfStudy")}
        className="mb-3"
      />
      <hr className="my-4" />
      <div className="uppercase text-sm font-medium mb-2">
        Graduation start date
      </div>
      <div className="grid">
        <div className="col">
          <Dropdown
            key={`graduation_start_month_${id}`}
            value={currentEducationItem.graduationStartMonth}
            onChange={dropdownChange("graduationStartMonth")}
            options={MONTHS.map((m) => ({
              label: m.label,
              value: m.id.toString(),
            }))}
            placeholder="Month"
            className="w-full"
          />
        </div>
        <div className="col">
          <Dropdown
            key={`graduation_start_year_${id}`}
            value={currentEducationItem.graduationStartYear}
            onChange={dropdownChange("graduationStartYear")}
            options={YEARS.map((yr) => ({
              label: yr.toString(),
              value: yr.toString(),
            }))}
            placeholder="Year"
            className="w-full"
          />
        </div>
      </div>

      <div className="flex align-items-center mb-2 mt-1">
        <div className="uppercase text-sm font-medium">
          Graduation end date:
        </div>
        <div className="flex align-items-center ml-3">
          <Checkbox
            inputId="still_attend_here"
            key={`still_attent_here_${id}`}
            name="pizza"
            value="Onion"
            onChange={checkboxChange("stillAttendHere")}
            checked={currentEducationItem.stillAttendHere}
          />
          <label htmlFor="still-attend-here" className="ml-2 text-sm">
            I still study here
          </label>
        </div>
      </div>

      <div className="grid">
        <div className="col">
          <Dropdown
            key={`graduation_end_month_${id}`}
            value={currentEducationItem.graduationEndMonth}
            onChange={dropdownChange("graduationEndMonth")}
            options={MONTHS.map((m) => ({
              label: m.label,
              value: m.id.toString(),
            }))}
            placeholder="Month"
            className="w-full"
            disabled={currentEducationItem.stillAttendHere}
          />
        </div>
        <div className="col">
          <Dropdown
            key={`graduation_end_year_${id}`}
            value={currentEducationItem.graduationEndYear}
            onChange={dropdownChange("graduationEndYear")}
            options={YEARS.map((yr) => ({
              label: yr.toString(),
              value: yr.toString(),
            }))}
            placeholder="Year"
            className="w-full"
            disabled={currentEducationItem.stillAttendHere}
          />
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <AppTextarea
        key={`school_description_${id}`}
        label="Additional description"
        onUpdate={handleChange("schoolDescription")}
        value={currentEducationItem.schoolDescription}
      />
    </>
  );
};

export default EducationDetail;
