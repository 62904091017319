import axios from "axios";
import "./helper";
import { API } from "../config/constants";

export const API_updateUserSummary = () =>
  axios.post<{ userSummaryImproved: string }>(`${API}ai`, {
    action: "improve_user_summary",
  });

export const API_updateUserAdditionalInformation = () =>
  axios.post<{ improvedAdditionalInformation: string }>(`${API}ai`, {
    action: "improve_additional_information",
  });

export const API_updateUserCoverLetter = () =>
  axios.post<{ improvedCoverLetter: string }>(`${API}ai`, {
    action: "improve_cover_letter",
  });
