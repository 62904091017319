import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { updateAllWorkHistoryItems, updateWorkHistoryItemElement } from "../../../store/data/actions";
import { MONTHS, YEARS } from "../../../constants";
import { useNavigate, useParams } from "react-router-dom";
import { AppInput, AppTextarea } from "../Common";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { API_dataUpdate } from "../../../services/dataApi";
import { generateNotificationId, useApiCallWithNotification } from "../../utils";
import { Button } from "primereact/button";

const WorkHistoryDetail = () => {
  const [confirmRemoveItem, setConfirmRemoveItem] = useState(false);
  const notificationId = generateNotificationId();
  const submit = useApiCallWithNotification(notificationId);

  let id = parseInt(useParams().id || "0");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { workHistory } = useAppSelector((app) => app.data);
  const currentWorkHistory = workHistory.find((item) => item.id === id);

  useEffect(() => {
    if (!currentWorkHistory) navigate("/work-history");
  }, [currentWorkHistory, navigate]);

  if (!currentWorkHistory) return null;

  const handleChange = (fieldName: string) => (updatedValue: string | number | boolean) => {
    dispatch(updateWorkHistoryItemElement(id, { [fieldName]: updatedValue }));
    submit(API_dataUpdate, "data", "workHistory", "Work history");
  };

  const dropdownChange = (field: string) => (event: DropdownChangeEvent) => handleChange(field)(event.value);
  const checkboxChange = (field: string) => (event: CheckboxChangeEvent) => handleChange(field)(event.checked || false);

  const confirmRemoveItemAction = () => setConfirmRemoveItem(true);
  const cancelConfirmRemoveItemAction = () => setConfirmRemoveItem(false);
  const removeItemAction = () => {
    const updatedWorkHistoryItems = workHistory.filter((historyItem) => historyItem.id !== currentWorkHistory.id);
    dispatch(updateAllWorkHistoryItems(updatedWorkHistoryItems));

    submit(API_dataUpdate, "data", "workHistory", "Work history").then(() => navigate("/account"));
  };

  return (
    <>
      <AppInput
        label="Job title"
        id="job_title"
        key={`job_title_${id}`}
        value={currentWorkHistory.jobTitle}
        isRequired
        onUpdate={handleChange("jobTitle")}
        className="mb-3"
      />
      <AppInput
        label="Employer name"
        id="employer_name"
        key={`employer_name_${id}`}
        value={currentWorkHistory.employer}
        onUpdate={handleChange("employer")}
        className="mb-3"
      />
      <hr className="my-4" />
      <AppInput
        label="City"
        id="city"
        key={`city_${id}`}
        value={currentWorkHistory.city}
        onUpdate={handleChange("city")}
        className="mb-3"
      />
      <AppInput
        label="State"
        id="state"
        key={`state_${id}`}
        value={currentWorkHistory.state}
        onUpdate={handleChange("state")}
        className="mb-3"
      />
      <hr className="my-4" />
      <div className="uppercase text-sm font-medium mb-2">Start date</div>
      <div className="grid">
        <div className="col">
          <Dropdown
            key={`start_date_month_${id}`}
            value={currentWorkHistory.startDateMonth}
            onChange={dropdownChange("startDateMonth")}
            options={MONTHS.map((m) => ({
              label: m.label,
              value: m.id.toString(),
            }))}
            placeholder="Month"
            className="w-full"
          />
        </div>
        <div className="col">
          <Dropdown
            key={`start_date_year_${id}`}
            value={currentWorkHistory.startDateYear}
            onChange={dropdownChange("startDateYear")}
            options={YEARS.map((yr) => ({
              label: yr.toString(),
              value: yr.toString(),
            }))}
            placeholder="Year"
            className="w-full"
          />
        </div>
      </div>
      <div className="flex align-items-center mb-2 mt-1">
        <div className="uppercase text-sm font-medium">End date</div>
        <div className="flex align-items-center ml-3">
          <Checkbox
            key={`still_work_here_${id}`}
            inputId="still-work-here"
            name="pizza"
            value="Onion"
            onChange={checkboxChange("stillWorkHere")}
            checked={currentWorkHistory.stillWorkHere}
          />
          <label htmlFor="still-work-here" className="ml-2 text-sm">
            I still work here
          </label>
        </div>
      </div>
      <div className="grid">
        <div className="col">
          <Dropdown
            key={`end_date_month_${id}`}
            value={currentWorkHistory.endDateMonth}
            onChange={dropdownChange("endDateMonth")}
            options={MONTHS.map((m) => ({
              label: m.label,
              value: m.id.toString(),
            }))}
            placeholder="Month"
            className="w-full"
            disabled={currentWorkHistory.stillWorkHere}
          />
        </div>
        <div className="col">
          <Dropdown
            key={`end_date_year_${id}`}
            value={currentWorkHistory.endDateYear}
            onChange={dropdownChange("endDateYear")}
            options={YEARS.map((yr) => ({
              label: yr.toString(),
              value: yr.toString(),
            }))}
            placeholder="Year"
            className="w-full"
            disabled={currentWorkHistory.stillWorkHere}
          />
        </div>
      </div>
      <hr className="mt-4 mb-2" />
      <AppTextarea
        key={`job_description_${id}`}
        label="Job description"
        onUpdate={handleChange("jobDescription")}
        value={currentWorkHistory.jobDescription}
      />
      <div className="mt-4 text-right">
        {confirmRemoveItem ? (
          <div>
            <Button
              outlined
              size="small"
              severity="danger"
              label="Yes, remove this item"
              onClick={removeItemAction}
              className="mr-2"
            />
            <Button outlined size="small" severity="info" label="Cancel" onClick={cancelConfirmRemoveItemAction} />
          </div>
        ) : (
          <Button outlined size="small" severity="danger" label="Remove this item" onClick={confirmRemoveItemAction} />
        )}
      </div>
    </>
  );
};

export default WorkHistoryDetail;
