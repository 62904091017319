import { useEffect, useState } from "react";
import { ImprovedCoverLetter } from "../../../store/data/types";
import { Button } from "primereact/button";
import { AppTextarea } from "../Common";
import { ProgressSpinner } from "primereact/progressspinner";
import { useAppDispatch } from "../../../store";
import { API_getLastUpdatedCoverLetter } from "../../../services/dataApi";
import { updateCoverLetter } from "../../../store/data/actions";
import React from "react";

interface IProps {
  improvedCoverLetter: ImprovedCoverLetter;
}

const ImprovedCoverLetterContainer = ({ improvedCoverLetter }: IProps) => {
  const dispatch = useAppDispatch();
  const [details, showDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleDetails = () => showDetails(!details);

  useEffect(() => {
    API_getLastUpdatedCoverLetter()
      .then((response) => response.data)
      .then((response) => {
        if (response.generationTime !== improvedCoverLetter.generationTime) {
          dispatch(updateCoverLetter({ improvedCoverLetter: response }));
        }
        setLoading(false);
      });
  }, [dispatch, improvedCoverLetter.generationTime]);

  if (loading) return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
  if (improvedCoverLetter.improvedCoverLetter === null) return null;

  if (!details)
    return (
      <Button
        text
        raised
        severity="secondary"
        size="small"
        className="p-2 mt-3"
        label="Show last improvements"
        onClick={toggleDetails}
      />
    );

  return (
    <div className="mt-5">
      <hr />
      <h5>
        Info: below you will find your cover letter recently revised by AI. It cannot be edited, but you can select text
        in it and, for example, copy it to your current Letter.
      </h5>
      <div className="flex sm:flex-column md:flex-row ">
        <div className={"sm:w-full md:w-6 md:mr-2"}>
          <AppTextarea
            value={improvedCoverLetter.oldCoverLetter || ""}
            label="Additional information"
            isDisabled={true}
            noFade={true}
          />
        </div>

        <div className="sm:w-full md:w-6  md:ml-2">
          <AppTextarea
            value={improvedCoverLetter.improvedCoverLetter}
            label="Improved"
            isDisabled={true}
            noFade={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ImprovedCoverLetterContainer;
