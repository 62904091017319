import { useEffect, useState } from "react";
import { ImprovedAdditionalInformation } from "../../../store/data/types";
import { Button } from "primereact/button";
import React from "react";
import { AppTextarea } from "../Common";
import { API_getLastUpdatedAdditionalInformation } from "../../../services/dataApi";
import { useAppDispatch } from "../../../store";
import { updateAdditionalInformation } from "../../../store/data/actions";
import { ProgressSpinner } from "primereact/progressspinner";

interface IProps {
  improvedAdditionalInformation: ImprovedAdditionalInformation;
}

const ImprovedAdditionalInformationContainer = ({ improvedAdditionalInformation }: IProps) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [details, showDetails] = useState(false);
  const toggleDetails = () => showDetails(!details);

  useEffect(() => {
    API_getLastUpdatedAdditionalInformation()
      .then((response) => response.data)
      .then((response) => {
        if (response.generationTime !== improvedAdditionalInformation.generationTime) {
          dispatch(
            updateAdditionalInformation({
              improvedAdditionalInformation: response,
            })
          );
        }
        setLoading(false);
      });
  }, [dispatch, improvedAdditionalInformation.generationTime]);

  if (loading) return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
  if (improvedAdditionalInformation.improvedAdditionalInfo === null) return null;

  if (!details)
    return (
      <Button
        text
        raised
        severity="success"
        size="small"
        className="p-2 mt-3"
        label="Show last improvements"
        onClick={toggleDetails}
      />
    );

  return (
    <div className="mt-5">
      <hr />
      <h5>
        Info: below you will find your summary recently revised by AI. It cannot be edited, but you can select text in
        it and, for example, copy it to your current summary.
      </h5>
      <div className="flex sm:flex-column md:flex-row ">
        <div className={"sm:w-full md:w-6 md:mr-2"}>
          <AppTextarea
            value={improvedAdditionalInformation.oldAdditionalInfo || ""}
            label="Additional information"
            isDisabled={true}
            noFade={true}
          />
        </div>

        <div className="sm:w-full md:w-6  md:ml-2">
          <AppTextarea
            value={improvedAdditionalInformation.improvedAdditionalInfo}
            label="Improved"
            isDisabled={true}
            noFade={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ImprovedAdditionalInformationContainer;
