import {WorkHistoryItem, EducationItem, Skill} from './types';

const workHistoryItemGenerator = (
  existingHistories: WorkHistoryItem[],
): WorkHistoryItem => {
  const newId = existingHistories.length
    ? Math.max(...existingHistories.map(el => el.id)) + 1
    : 0;

  return {
    id: newId,
    jobTitle: '',
    employer: '',
    city: '',
    state: '',
    startDateMonth: '0',
    startDateYear: '0',
    endDateMonth: '0',
    endDateYear: '0',
    stillWorkHere: false,
    order: newId,
    jobDescription: ''
  };
};

const educationItemGenerator = (
  existingItems: EducationItem[],
): EducationItem => {
  const newId = existingItems.length
    ? Math.max(...existingItems.map(el => el.id)) + 1
    : 0;

  return {
    id: newId,
    schoolName: '',
    schoolLocation: '',
    degree: '',
    customDegree: '',
    fieldOfStudy: '',
    graduationStartMonth: '',
    graduationStartYear: '',
    graduationEndMonth: '',
    graduationEndYear: '',
    stillAttendHere: false,
    order: newId,
    schoolDescription: ''
  };
};

const skillItemGenerator = (existingItems: Skill[]) => {
  const newId = existingItems.length
    ? Math.max(...existingItems.map(el => el.id)) + 1
    : 0;

  return {
    id: newId,
    order: newId,
    skillName: '',
    level: 0,
  };
};

export {workHistoryItemGenerator, educationItemGenerator, skillItemGenerator};
