import { useEffect, useState } from "react";
import { ImprovedSummaryResumeItem } from "../../../store/data/types";
import { Button } from "primereact/button";
import { AppTextarea } from "../Common";
import { API_getLastUpdatedUserSummary } from "../../../services/dataApi";
import { ProgressSpinner } from "primereact/progressspinner";
import { updateUserBaseInfo } from "../../../store/data/actions";
import { useAppDispatch } from "../../../store";
import React from "react";

interface IProps {
  improvedSummary: ImprovedSummaryResumeItem;
}

const ImprovedSummary = ({ improvedSummary }: IProps) => {
  const dispatch = useAppDispatch();
  const [details, showDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const toggleDetails = () => {
    showDetails(!details);
  };

  useEffect(() => {
    API_getLastUpdatedUserSummary()
      .then((response) => response.data)
      .then((response) => {
        if (response.generationTime !== improvedSummary.generationTime) {
          dispatch(updateUserBaseInfo({ improvedSummary: response }));
        }
        setLoading(false);
      });
  }, [dispatch, improvedSummary.generationTime]);

  if (loading) return <ProgressSpinner style={{ width: "30px", height: "30px" }} />;
  if (improvedSummary.improvedSummary === null) return null;

  if (!details)
    return (
      <Button
        text
        raised
        severity="secondary"
        size="small"
        className="p-2 mt-3"
        label="Show last improvements"
        onClick={toggleDetails}
      />
    );

  return (
    <div className="mt-5">
      <hr />
      <h5>
        Info: below you will find your summary recently revised by AI. It cannot be edited, but you can select text in
        it and, for example, copy it to your current summary.
      </h5>
      <div className="flex sm:flex-column md:flex-row ">
        <div className={"sm:w-full md:w-6 md:mr-2"}>
          <AppTextarea
            value={improvedSummary.oldSummary || ""}
            label="Summary of Qualifications"
            isDisabled={true}
            noFade={true}
          />
        </div>

        <div className="sm:w-full md:w-6  md:ml-2">
          <AppTextarea
            value={improvedSummary.improvedSummary}
            label="Improved summary"
            isDisabled={true}
            noFade={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ImprovedSummary;
