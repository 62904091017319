import { useEffect, useRef, useState } from "react";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { addUpdateNotificationQueueItem } from "../store/application/actions";
import {
  NotificationQueueItemStatus,
  NotificationQueueItem,
} from "../store/application/types";
import { ResumeItem } from "../store/data/types";
import { User } from "../store/account/types";

export function useApiCallWithNotification(notificationId: string) {
  let [myTimeout, setMyTimeout] = useState<ReturnType<typeof setTimeout>>();
  const dispatch = useAppDispatch();
  let dataToSendRef = useRef<Partial<RootState> | null>(null);
  const { data, user } = useAppSelector((app) => app);

  useEffect(() => {
    dataToSendRef.current = { data, user };
  }, [data, user]);

  useEffect(() => {
    return () => {
      clearTimeout(myTimeout);
    };
  });

  const submitNotification = (
    status: NotificationQueueItemStatus,
    elementName: string
  ) => {
    const detail =
      status === "failed"
        ? `${elementName} failed to save on the server`
        : `${elementName} successfully updated`;
    const notificationItem: NotificationQueueItem = {
      id: notificationId,
      elementName: detail,
      status,
    };
    dispatch(addUpdateNotificationQueueItem(notificationItem));
  };

  const submit = (
    apiCall: any,
    apiBase: "user" | "data",
    apiData: keyof ResumeItem | keyof User,
    notificationName: string
  ) => {
    if (myTimeout) clearTimeout(myTimeout);

    return new Promise((resolve) => {
      const timeout = setTimeout(() => {
        if (!dataToSendRef.current) return;
        const data =
          apiBase === "data"
            ? dataToSendRef?.current?.data![apiData as keyof ResumeItem]
            : dataToSendRef?.current?.user![apiData as keyof User];

        submitNotification("pending", notificationName);
        return resolve(
          apiCall({ data, field: apiData })
            .then(() => {
              submitNotification("success", notificationName);
            })
            .catch(() => {
              submitNotification("failed", notificationName);
            })
        );
      }, 1000);
      setMyTimeout(timeout);
    });
  };

  return submit;
}

export function generateNotificationId() {
  return window.performance.now().toFixed(2);
}
