export const LOAD_ALL_DATA = "LOAD_ALL_DATA";
export const SELECT_TEMPLATE_ID = "SELECT_TEMPLATE_ID";
export const UPDATE_RESUME_ELEMENT_USER_BASE_INFO =
  "UPDATE_RESUME_ELEMENT_USER_BASE_INFO";
export const ADD_WORK_HISTORY_ITEM = "ADD_WORK_HISTORY_ITEM";
export const REMOVE_WORK_HISTORY_ITEM = "REMOVE_WORK_HISTORY_ITEM";
export const UPDATE_WORK_HISTORY_ITEM_ELEMENT =
  "UPDATE_WORK_HISTORY_ITEM_ELEMENT";
export const UPDATE_ALL_WORK_HISTORY_ITEMS = "UPDATE_ALL_WORK_HISTORY_ITEMS";
export const ADD_EDUCATION_ITEM = "ADD_EDUCATION_ITEM";
export const REMOVE_EDUCATION_ITEM = "REMOVE_EDUCATION_ITEM";
export const UPDATE_EDUCATION_ITEM_ELEMENT = "UPDATE_EDUCATION_ITEM_ELEMENT";
export const UPDATE_ALL_EDUCATION_ITEMS = "UPDATE_ALL_EDUCATION_ITEMS";
export const ADD_SKILL_ITEM = "ADD_SKILL_ITEM";
export const REMOVE_SKILL_ITEM = "REMOVE_SKILL_ITEM";
export const UPDATE_SKILL_ITEM = "UPDATE_SKILL_ITEM";
export const UPDATE_ALL_SKILL_ITEMS = "UPDATE_ALL_SKILL_ITEMS";
export const UPDATE_ADDITIONAL_INFORMATION = "UPDATE_ADDITIONAL_INFORMATION";
export const UPDATE_COVER_LETTER = "UPDATE_COVER_LETTER";

export interface ResumeItem {
  template: TemplateId;
  baseUserInfo: BaseUserInfo;
  workHistory: WorkHistoryItem[];
  education: EducationItem[];
  skills: Skill[];
  additionalInformation: AdditionalInformation;
  coverLetter: CoverLetter;
}

export interface TemplateId {
  id: number;
}

export interface CoverLetter {
  text: string;
  improvedCoverLetter: ImprovedCoverLetter;
  template: {
    id: number;
  };
  recipient: string;
  dateInHeader: boolean;
}

export interface ImprovedCoverLetter {
  improvedCoverLetter: string | null;
  oldCoverLetter: string | null;
  generationTime: string | null;
}

export interface AdditionalInformation {
  text: string;
  improvedAdditionalInformation: ImprovedAdditionalInformation;
}

export interface ImprovedAdditionalInformation {
  improvedAdditionalInfo: string | null;
  oldAdditionalInfo: string | null;
  generationTime: string | null;
}

export interface Skill {
  id: number;
  order: number;
  skillName: string;
  level: number;
}

export interface EducationItem {
  id: number;
  schoolName: string;
  schoolLocation: string;
  degree: string;
  customDegree: string;
  fieldOfStudy: string;
  graduationStartMonth: string;
  graduationStartYear: string;
  graduationEndMonth: string;
  graduationEndYear: string;
  stillAttendHere: boolean;
  order: number;
  schoolDescription: string;
}

export type SocialMediaUrlType = "web" | "linkedin" | "twitter";

export interface SocialMediaUrl {
  id: number;
  type: SocialMediaUrlType;
  url: string;
}

export interface ImprovedSummaryResumeItem {
  improvedSummary: string | null;
  oldSummary: string | null;
  generationTime: string | null;
}

export interface BaseUserInfo {
  firstName: string;
  lastName: string;
  profession: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  email: string;
  socialMediaUrl: SocialMediaUrl[];
  summaryOfQualifications: string;
  improvedSummary: ImprovedSummaryResumeItem;
}

export interface WorkHistoryItem {
  id: number;
  jobTitle: string;
  employer: string;
  city: string;
  state: string;
  startDateMonth: string;
  startDateYear: string;
  endDateMonth: string;
  endDateYear: string;
  stillWorkHere: boolean;
  order: number;
  jobDescription: string;
}
