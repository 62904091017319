import { ResumeItem } from "../../../../store/data/types";
import templateSidebar from "./templateSidebar";
import { Page, View, Document, StyleSheet, Font } from "@react-pdf/renderer";
import templateCoverLetterContent from "./templateCoverLetterContent";
Font.register({
  family: "Roboto",
  fonts: [
    {
      src: require("../../../../assets/roboto/Roboto-Light.ttf"),
      fontWeight: "light",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-LightItalic.ttf"),
      fontWeight: "light",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Regular.ttf"),
      fontWeight: "normal",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-Italic.ttf"),
      fontWeight: "normal",
      fontStyle: "italic",
    },

    {
      src: require("../../../../assets/roboto/Roboto-Bold.ttf"),
      fontWeight: "bold",
      fontStyle: "normal",
    },
    {
      src: require("../../../../assets/roboto/Roboto-BoldItalic.ttf"),
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    margin: 0,
    fontWeight: "normal",
    lineHeight: 1.3,
    color: "#444",
    hyphens: "auto",
    display: "flex",
    fontSize: "8pt",
    fontFamily: "Roboto",
  },
  sectionMain: {
    marginTop: "0.1in",
    marginLeft: 10,
    marginRight: 15,
    width: 430,
    fontSize: "8pt",
    paddingBottom: 10,
  },
  sectionSidebar: {
    position: "relative" /* for disclaimer */,
    height: "100%",
    backgroundColor: "#f2f2f2",
    width: 140,
    borderLeftStyle: "solid",
    borderLeftColor: "#f2f2f2",
    borderLeftWidth: 1,
    marginLeft: "auto",
  },
});

const Template_4 = (item: ResumeItem) => (
  <Document>
    <Page size="A4" style={styles.page} wrap>
      <View style={styles.sectionMain}>
        <View
          fixed
          render={({ pageNumber }) =>
            pageNumber === 1 ? null : (
              <View
                style={{
                  height: 20,
                }}
              />
            )
          }
        ></View>
        {templateCoverLetterContent(item)}

        <View
          fixed
          render={() => (
            <View
              style={{
                height: 20,
              }}
            />
          )}
        ></View>
      </View>
      <View
        style={styles.sectionSidebar}
        fixed
        render={({ pageNumber }) => <View key={pageNumber}>{pageNumber === 1 && templateSidebar(item)}</View>}
      />
    </Page>
  </Document>
);

export default Template_4;
