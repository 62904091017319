import React from "react";
import Html from "react-pdf-html";

interface IProps {
  htmlCode: string;
  maxWidth: number;
}

const HtmlBlock = ({ htmlCode, maxWidth }: IProps) => {
  if (!htmlCode.length) {
    return null;
  }

  const fixedHtmlCode = `
    <html>
      <body>
      <style>
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-Light.ttf') format('truetype')
          font-weight: light;
          font-style: normal;
        }
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-LightItalic.ttf') format('truetype')
          font-weight: light;
          font-style: italic;
        }
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-Regular.ttf') format('truetype')
          font-weight: normal;
          font-style: normal;
        }
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-Italic.ttf') format('truetype')
          font-weight: normal;
          font-style: italic;
        }
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-Bold.ttf') format('truetype')
          font-weight: bold;
          font-style: normal;
        }
        @font-face {
          font-family: 'Roboto';
          src: url('../../../../assets/roboto/Roboto-BoldItalic.ttf') format('truetype')
          font-weight: bold;
          font-style: italic;
        }
        html, body{
          margin: 0;
          padding: 0;
          font-family: 'Roboto';
        }
      </style>
      ${htmlCode}
      </body>
    </html>`;

  const stylesheet = {
    body: {
      maxWidth,
    },
    p: {
      margin: 0,
      lineHeight: 1.3,
      wordWrap: "break-word",
      textAlign: "justify",
      fontSize: 8,
    },
    ul: {
      lineHeight: 1.3,
      maxWidth: maxWidth - 30,
      margin: 0,
      paddingLeft: 0,
    },
    li: {
      fontSize: 8,
      marginTop: 4,
      marginBottom: 1,
    },
    ol: {
      lineHeight: 1.3,
      maxWidth: maxWidth - 30,
      margin: 0,
      paddingLeft: 0,
    },
    h1: {
      fontSize: 14,
      marginTop: 10,
      marginBottom: 10,
    },
    h2: {
      fontSize: 10,
      marginTop: 8,
      marginBottom: 8,
    },
  };
  return <Html stylesheet={stylesheet}>{fixedHtmlCode}</Html>;
};

export default HtmlBlock;
